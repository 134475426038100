import React, { useState, MouseEvent, FormEventHandler } from "react";
import { Layout } from "../layout";
import { FormElem, IAppState, IField, IFieldCollection } from "../../types";
import { useSelector, useDispatch } from "react-redux";
import { Link, navigate } from "@reach/router";
import axios from "axios";
import { setFields } from "../../state/actions";
import { Edit } from "../../common/icons";
import notify from "../../utils/notify";

const defaultFieldData = { title: "", url: "" };

const Fields = () => {
  const fields = useSelector((state: IAppState) =>
    Object.values(state.fields).reduce(
      (reduction: IFieldCollection, field: IField) => {
        if (field.active === 1) {
          reduction[field.id] = field;
        }
        return reduction;
      },
      {}
    )
  );
  const [overlay, setOverlay] = useState(false);
  const [overlaySaving, setOverlaySaving] = useState(false);
  const [fieldData, setFieldData] = useState({ ...defaultFieldData });
  const hasFields = Object.keys(fields).length > 0;
  const dispatch = useDispatch();

  const newField = () => {
    setFieldData({ ...defaultFieldData });
    setOverlay(true);
  };

  const createField = (e: MouseEvent) => {
    e.preventDefault();
    setOverlaySaving(true);

    try {
      axios
        .post(`/.netlify/functions/addField`, { field: { ...fieldData } })
        .then(({ data }) => {
          dispatch(setFields([data]));
          setOverlaySaving(false);
          navigate(`/manage/fields/${data.id}/edit`);
        });
    } catch (e) {
      notify("Error creating Field", "error");
    }
  };

  const closeOverlay = (e: MouseEvent) => {
    e.preventDefault();
    setOverlay(false);
  };

  const handleChange: FormEventHandler<FormElem> = (e) => {
    const {
      currentTarget: { name, value },
    } = e;

    const newFieldData = { ...fieldData };
    newFieldData[name as "title" | "url"] = value;
    setFieldData(newFieldData);
  };

  const overlayForm = (
    <div className={`overlay-container ${overlaySaving ? "saving" : ""}`}>
      <form>
        <h3>Create new field</h3>
        <div className="form-row">
          <label>Title</label>
          <input
            value={fieldData.title}
            type="text"
            name="title"
            onChange={handleChange}
          />
        </div>
        <div className="form-row">
          <label>Squarespace URL</label>
          <input
            value={fieldData.url}
            type="text"
            name="url"
            onChange={handleChange}
          />
        </div>
        <button onClick={createField}>Create</button>
        <button className="cancel" onClick={closeOverlay}>
          Cancel
        </button>
      </form>
    </div>
  );

  return (
    <Layout>
      {hasFields ? (
        <div className="fields content">
          <div className="title-bar">
            <h1>Fields</h1>
            <Link to="/manage/fields/inactive">Inactive fields</Link>
            <button onClick={newField}>Create new</button>
          </div>
          <div className="edit-items">
            {Object.values(fields).map((field) => {
              return (
                <Link
                  className="button"
                  key={`field-${field.id}`}
                  to={`/manage/fields/${field.id}/edit`}
                >
                  {field.title}
                  <Edit />
                </Link>
              );
            })}
          </div>
          {overlay && overlayForm}
        </div>
      ) : (
        <p>Field data could not be found</p>
      )}
    </Layout>
  );
};

export default Fields;
