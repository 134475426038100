import React, { useEffect, useState } from "react";
import { Layout } from "../layout";
import { IAppState, IFieldCollection, IIPad } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "@reach/router";
import { saveIPad } from "../../state/actions";
import notify from "../../utils/notify";

const IPad = () => {
  const { ipadId } = useParams();
  const iPad: IIPad = useSelector((state: IAppState) => state.ipads[ipadId]);
  const fields: IFieldCollection = useSelector(
    (state: IAppState) => state.fields
  );
  const fieldList = Object.values(fields);
  const [values, setValues] = useState<IIPad>({ ...iPad });
  const hasData = fieldList.length && iPad !== undefined;
  const dispatch = useDispatch();

  const handleSave = async () => {
    notify("Saving IPad...");
    const newIPad = { ...iPad, ...values };
    dispatch(saveIPad(newIPad));
  };

  useEffect(() => {
    setValues({ ...iPad });
  }, [hasData, iPad]);

  return (
    <Layout>
      {hasData ? (
        <div className="layout-full no-padding">
          <header className="subnav">
            <div></div>
            <div>
              <Link to="/manage/ipads">Cancel</Link>
              <button onClick={handleSave} className="big-button">
                Save
              </button>
            </div>
          </header>
          <div className="content">
            <h1>{iPad.name}</h1>
            <form>
              <div className="form-row">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      name: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="form-row">
                <label>Field</label>
                <select
                  name="field_id"
                  value={values.field_id}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      field_id: parseInt(e.currentTarget.value),
                    })
                  }
                >
                  <option value="">-- Please select --</option>
                  {fieldList.map((field) => (
                    <option
                      key={`field-${field.id}`}
                      value={field.id}
                      disabled={field.active === 0}
                    >
                      {field.title}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default IPad;
