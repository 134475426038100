import React from "react";
import ob03 from "./ob-03.svg";

const Onboarding03 = () => {
  return (
    <div className="onboarding-slide onboarding-slide-03">
      <div className="onboarding-image">
        <img src={ob03} alt="Onboarding, step 3" />
      </div>
      <div className="onboarding-copy">
        <p>
          When you answer a question, an Artificial Intelligence (GPT-3.5) turns
          it into a new question for other visitors to answer.
        </p>
        <p>This shapes the next visitor’s Field.</p>
      </div>
    </div>
  );
};

export default Onboarding03;
