import React from "react";
import { Layout } from "../layout";
import { IAppState, IField, IFieldCollection, IIPad } from "../../types";
import { useSelector } from "react-redux";
import { Link } from "@reach/router";

const Labels = () => {
  const iPads: IIPad[] = useSelector((state: IAppState) => state.ipads);
  const fields = useSelector((state: IAppState) =>
    Object.values(state.fields).reduce(
      (reduction: IFieldCollection, field: IField) => {
        if (field.active === 1) {
          reduction[field.id] = field;
        }
        return reduction;
      },
      {}
    )
  );
  const hasData =
    Object.values(fields).length > 0 && Object.values(iPads).length > 0;

  return (
    <Layout showHeader={false}>
      {hasData ? (
        <div className="button-list-wrap">
          <div className="button-list">
            {Object.entries(iPads).map(([_, value]) => {
              return value.field_id && fields[value.field_id] ? (
                <Link
                  className="button"
                  key={`label-${value.id}`}
                  to={`/labels/${value.id}`}
                >
                  {value.name} - {fields[value.field_id].title}
                </Link>
              ) : null;
            })}
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default Labels;
