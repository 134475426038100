import React from "react";

const FieldClose = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.93333 0.866667C6.93333 1.27049 6.65714 1.60981 6.28335 1.70602V4.8919L7.45053 5.56578L10.419 4.08153C10.4066 4.02298 10.4 3.96225 10.4 3.89998C10.4 3.42133 10.788 3.03331 11.2667 3.03331C11.7453 3.03331 12.1333 3.42133 12.1333 3.89998C12.1333 4.37863 11.7453 4.76665 11.2667 4.76665C11.0058 4.76665 10.7719 4.6514 10.613 4.46904L7.56777 5.99164V7.00822L10.613 8.53081C10.7718 8.34845 11.0058 8.2332 11.2667 8.2332C11.7453 8.2332 12.1333 8.62122 12.1333 9.09987C12.1333 9.57851 11.7453 9.96653 11.2667 9.96653C10.788 9.96653 10.4 9.57851 10.4 9.09987C10.4 9.0376 10.4066 8.97687 10.419 8.91833L7.45091 7.43427L6.28335 8.10837V11.2941C6.65714 11.3903 6.93333 11.7296 6.93333 12.1335C6.93333 12.5373 6.65714 12.8766 6.28335 12.9728V13H6.08208L6.06666 13.0001L6.05124 13H5.85001V12.9728C5.4762 12.8766 5.19999 12.5373 5.19999 12.1335C5.19999 11.7296 5.4762 11.3903 5.85001 11.2941V8.10839L4.68241 7.43427L1.71429 8.91833C1.72677 8.97687 1.73333 9.0376 1.73333 9.09987C1.73333 9.57851 1.34531 9.96653 0.866667 9.96653C0.38802 9.96653 0 9.57851 0 9.09987C0 8.62122 0.38802 8.2332 0.866667 8.2332C1.12753 8.2332 1.36147 8.34845 1.52036 8.53081L4.56555 7.00821V5.99165L1.52035 4.46904C1.36146 4.6514 1.12752 4.76665 0.866667 4.76665C0.38802 4.76665 0 4.37863 0 3.89998C0 3.42133 0.38802 3.03331 0.866667 3.03331C1.34531 3.03331 1.73333 3.42133 1.73333 3.89998C1.73333 3.96225 1.72677 4.02298 1.71429 4.08153L4.68279 5.56578L5.85001 4.89188V1.70604C5.4762 1.60983 5.19999 1.27051 5.19999 0.866667C5.19999 0.38802 5.58801 0 6.06666 0C6.54531 0 6.93333 0.38802 6.93333 0.866667Z"
        fill="black"
      />
    </svg>
  );
};

export default FieldClose;
