import { IAppState, IGraphData, IGraphReduction } from "../types";

export const graphReduce = (state: IAppState, fieldId: number) => {
  return (
    state.graph[fieldId] &&
    state.graph[fieldId].reduce(
      (obj: IGraphReduction, item: IGraphData) => {
        obj.graph[item.id] = item;
        const parents = item.path.split(".").slice(0, -1);

        for (let parent of parents) {
          obj.parents[parseInt(parent)] = true;
        }
        return obj;
      },
      {
        graph: {},
        parents: {},
      }
    )
  );
};
