import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Layout } from "../layout";

const NotFound = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Layout className="content" showHeader={isAuthenticated}>
      <div>Sorry, the page you are looking for can't be found</div>
    </Layout>
  );
};

export default NotFound;
