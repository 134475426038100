import React from "react";

const FieldLock = () => {
  return (
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="lock"
    >
      <path d="M0 70L70 1" stroke="black" />
      <path d="M69 69L0.5 0.5" stroke="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5 32.6667H39.75V31.0476C39.75 28.8133 38.07 27 36 27C33.93 27 32.25 28.8133 32.25 31.0476V32.6667H31.5C30.675 32.6667 30 33.3952 30 34.2857V42.381C30 43.2714 30.675 44 31.5 44H40.5C41.325 44 42 43.2714 42 42.381V34.2857C42 33.3952 41.325 32.6667 40.5 32.6667ZM36 39.9524C35.175 39.9524 34.5 39.2238 34.5 38.3333C34.5 37.4429 35.175 36.7143 36 36.7143C36.825 36.7143 37.5 37.4429 37.5 38.3333C37.5 39.2238 36.825 39.9524 36 39.9524ZM33.675 31.0476V32.6667H38.325V31.0476C38.325 29.6633 37.2825 28.5381 36 28.5381C34.7175 28.5381 33.675 29.6633 33.675 31.0476Z"
        fill="black"
      />
    </svg>
  );
};

export default FieldLock;
