import React from "react";
import { Link } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeft } from "../../common/icons";
import { saveField } from "../../state/actions";
import { IAppState, IField, IFieldCollection } from "../../types";
import { Layout } from "../layout";

const InactiveFields = () => {
  const fields = useSelector((state: IAppState) =>
    Object.values(state.fields).reduce(
      (reduction: IFieldCollection, field: IField) => {
        if (field.active === 0) {
          reduction[field.id] = field;
        }
        return reduction;
      },
      {}
    )
  );

  const dispatch = useDispatch();

  const reActivate = async (field: IField) => {
    const newField = { ...field, active: 1 };
    dispatch(saveField(newField));
  };

  return (
    <Layout>
      <div className="inactive-fields content">
        <div className="title-bar">
          <h1>
            <Link to="/manage/fields">
              <ArrowLeft />
            </Link>
            Inactive Fields
          </h1>
        </div>
        <div className="edit-items">
          {fields &&
            Object.values(fields).map((field) => {
              return (
                <div className="edit-row" key={`field-${field.id}`}>
                  {field.title}
                  <button onClick={() => reActivate(field)}>Re-activate</button>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default InactiveFields;
