import React from "react";

const FieldIconSmall = () => {
  return (
    <svg
      width="125"
      height="122"
      viewBox="0 0 125 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="62.5"
        cy="61"
        rx="61.5"
        ry="60"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
      <ellipse
        cx="62.5"
        cy="61"
        rx="30.5"
        ry="30"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
      <ellipse
        cx="63"
        cy="61"
        rx="46"
        ry="45"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
      <rect x="55" y="54" width="3" height="3" fill="black" />
      <rect x="55" y="66" width="3" height="3" fill="black" />
      <rect x="67" y="54" width="3" height="3" fill="black" />
      <rect x="67" y="66" width="3" height="3" fill="black" />
      <rect x="70" y="69" width="3" height="3" fill="black" />
      <rect x="64" y="69" width="3" height="3" fill="black" />
      <rect x="70" y="63" width="3" height="3" fill="black" />
      <rect x="64" y="63" width="3" height="3" fill="black" />
      <rect
        x="52"
        y="51"
        width="9"
        height="9"
        rx="1"
        stroke="black"
        strokeWidth="2"
      />
      <rect
        x="52"
        y="63"
        width="9"
        height="9"
        rx="1"
        stroke="black"
        strokeWidth="2"
      />
      <rect
        x="64"
        y="51"
        width="9"
        height="9"
        rx="1"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
};

export default FieldIconSmall;
