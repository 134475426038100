import { Link, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModerationQuestion } from ".";
import { ArrowLeft } from "../../common/icons";
import { loadQuestions } from "../../state/actions";
import {
  IAppState,
  IQuestionCollection,
  FilterVal,
  IFilterValCollection,
  IQuestion,
  // IQuestion,
} from "../../types";
import { Layout } from "../layout";

const statii: IFilterValCollection = {
  0: "unmoderated",
  1: "approved",
  2: "rejected",
};

const filterList = (questions: IQuestionCollection, filter: FilterVal) => {
  return (
    questions &&
    Object.values(questions).filter(
      (q) =>
        filter === "all" ||
        (filter === "seed" && !q.answer_id) ||
        filter === statii[q.status]
    )
  );
};

const FieldModeration = () => {
  const { fieldId } = useParams();
  const [filter, setFilter] = useState<FilterVal>("unmoderated");
  const field = useSelector((state: IAppState) => state.fields[fieldId]);
  const questions: IQuestion[] = useSelector((state: IAppState) => {
    return (
      state.questions[fieldId] && filterList(state.questions[fieldId], filter)
    );
  });
  const filterVals: FilterVal[] = [
    "all",
    "unmoderated",
    "approved",
    "rejected",
    "seed",
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadQuestions(fieldId));
  }, [dispatch, fieldId]);

  return (
    <Layout
      condition={!!questions}
      className={!!questions ? "" : "loading-questions"}
    >
      <div className="field-moderation content">
        <div className="title-bar">
          <h1>
            <div className="title">
              <Link to="/manage/moderation">
                <ArrowLeft />
              </Link>
              {field?.title}
            </div>
          </h1>
          <div className="filter">
            {filterVals.map((fv) => (
              <button
                className={fv === filter ? "active" : ""}
                key={`filter-${fv}`}
                onClick={() => setFilter(fv)}
              >
                {fv}
              </button>
            ))}
          </div>
        </div>
        <div className={`questions ${filter}`}>
          {questions &&
            questions.map((q) => (
              <ModerationQuestion
                qid={q.id}
                fieldId={fieldId}
                key={`question-row-${q.id}`}
              />
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default FieldModeration;
