import React from "react";
import { Layout } from "../layout";
import { Profile } from "../auth";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const { isAuthenticated } = useAuth0();

  return <Layout>{isAuthenticated && <Profile />}</Layout>;
};

export default Login;
