import { IGraphData, INodeDetail } from "../../types";
import * as d3 from "d3";
import { localStorageGet } from "../../utils/local-storage";
import { nodeSize } from "./utils";

export const answerNodeFromLSDatum = (datum: any): INodeDetail => ({
  id: `a${datum.id}`,
  size: nodeSize("answer"),
  type: "answer",
  parent: `q${datum.qid}`,
  generation: datum.generation,
  dbid: datum.id,
  mine: true,
  answer: datum.answer,
  created_at: datum.created_at,
});

export const processData = (
  graphData: IGraphData[],
  fieldId: number
): d3.HierarchyNode<unknown> => {
  const nodes: INodeDetail[] = [
    {
      id: "q0",
      size: nodeSize("root"),
      type: "root",
      parent: "",
      generation: 0,
    },
  ];

  const myAnswers = JSON.parse(localStorageGet("answers", "{}"));

  const nodeHash: any = {};
  const maxSize =
    Math.max.apply(
      Math,
      graphData.map((gd) => gd.size)
    ) || 1;

  graphData.forEach(({ id, size, answer_id, question_id, generation }) => {
    const qid = `q${id}`,
      aid = answer_id ? `a${answer_id}` : "",
      aqid = question_id ? `q${question_id}` : "";

    // question
    if (!nodeHash[qid]) {
      const node: INodeDetail = {
        id: qid,
        size: nodeSize("question", size / maxSize),
        type: "question",
        parent: aid || "q0",
        generation,
        dbid: id,
      };
      nodes.push(node);
      nodeHash[qid] = true;
    }

    // linked answer
    if (aid !== "" && !nodeHash[aid]) {
      const isMine =
        myAnswers[fieldId] &&
        myAnswers[fieldId].find((a: any) => a.id === answer_id);

      const node: INodeDetail = {
        id: aid,
        size: nodeSize("answer"),
        type: "answer",
        parent: aqid,
        generation,
        dbid: answer_id,
        mine: isMine,
      };
      nodes.push(node);
      nodeHash[aid] = true;
    }
  });

  if (myAnswers[fieldId]) {
    for (const answer of myAnswers[fieldId] as any) {
      const newNode = answerNodeFromLSDatum(answer);

      if (!nodeHash[newNode.id] && nodeHash[newNode.parent]) {
        nodeHash[newNode.id] = true;
        nodes.push(newNode);
      }
    }
  }

  const stratify = d3
    .stratify()
    .id((d: any) => d.id)
    .parentId((d: any) => d.parent);
  console.log(nodes);
  const root = stratify(nodes);
  return root;
};
