import {
  SET_QUESTIONS,
  SET_QUESTION,
  SET_FIELDS,
  SET_IPADS,
  ADD_QA,
  UPDATE_QA,
  UPDATE_FIELD,
  UPDATE_IPAD,
  CONTENT_STATUS,
  CONTENT_STATUS_LOADING,
  CONTENT_STATUS_LOADED,
  CONTENT_STATUS_ERROR,
  SET_GRAPH,
  SET_RANDOM_QUESTION,
  // SET_ACTIVE_FIELD,
} from "./actionTypes";
import { IField, IGraphData, IIPad, IPrimerQA, IQuestion } from "../types";
import axios from "axios";
import { Dispatch } from "redux";
import notify from "../utils/notify";

export function initContent() {
  return async (dispatch: Dispatch) => {
    dispatch(contentStatus(CONTENT_STATUS_LOADING));
    try {
      const { data } = await axios.get(`/.netlify/functions/getContent`);
      dispatch(setFields(data.fields));
      dispatch(setIPads(data.ipads));
      dispatch(contentStatus(CONTENT_STATUS_LOADED));
    } catch (e) {
      dispatch(contentStatus(CONTENT_STATUS_ERROR));
    }
  };
}

export function contentStatus(contentStatus: number) {
  return {
    type: CONTENT_STATUS,
    contentStatus,
  };
}

// export function loadField(fieldId: number) {
//   return (dispatch: Dispatch) => {
//     axios
//       .get(`/.netlify/functions/getField?field_id=${fieldId}`)
//       .then(({ data }) => {
//         dispatch(setFields([data]));
//       });
//   };
// }

// export function loadFields() {
//   return (dispatch: Dispatch) => {
//     axios.get("/.netlify/functions/getFields").then(({ data }) => {
//       dispatch(setFields(data.fields || []));
//     });
//   };
// }

export function saveField(field: IField) {
  return (dispatch: Dispatch) => {
    axios.put(`/.netlify/functions/updateField`, { field }).then(({ data }) => {
      dispatch(setFields(data.fields || []));
      notify("Field saved", "success");
    });
  };
}

export function setFields(fields: IField[]) {
  return {
    type: SET_FIELDS,
    fields,
  };
}

export function updateField(field: IField) {
  return {
    type: UPDATE_FIELD,
    field,
  };
}

// export function loadIPads() {
//   return (dispatch: Dispatch) => {
//     axios.get("/.netlify/functions/getIPads").then(({ data }) => {
//       dispatch(setIPads(data.ipads || []));
//     });
//   };
// }

export function saveIPad(ipad: IIPad) {
  return (dispatch: Dispatch) => {
    axios.put(`/.netlify/functions/updateIPad`, { ipad }).then(({ data }) => {
      dispatch(updateIPad(data));
      notify("IPad saved", "success");
    });
  };
}

export function setIPads(ipads: IIPad[]) {
  return {
    type: SET_IPADS,
    ipads,
  };
}

export function updateIPad(ipad: IIPad) {
  return {
    type: UPDATE_IPAD,
    ipad,
  };
}

export function addQA(qa: IPrimerQA) {
  return {
    type: ADD_QA,
    qa,
  };
}

export function updateQA(index: number, qa: IPrimerQA) {
  return {
    type: UPDATE_QA,
    index,
    qa,
  };
}

export function loadQuestions(fieldId: number) {
  return (dispatch: Dispatch) => {
    axios
      .get(`/.netlify/functions/getQuestions?fieldId=${fieldId}`)
      .then(({ data }) => {
        dispatch(setQuestions(data.questions, fieldId));
      });
  };
}

export function setQuestions(questions: IQuestion[], fieldId: number) {
  return {
    type: SET_QUESTIONS,
    questions,
    fieldId,
  };
}

export function setQuestion(question: IQuestion[]) {
  return {
    type: SET_QUESTION,
    question,
  };
}

export function updateQuestion(questionId: number, status: number) {
  return (dispatch: Dispatch) => {
    axios
      .put(`/.netlify/functions/updateQuestion`, {
        questions: { id: questionId, status },
      })
      .then(({ data }) => {
        dispatch(setQuestion(data));
      });
  };
}

export function loadGraph(fieldId: number) {
  return (dispatch: Dispatch) => {
    axios
      .get(`/.netlify/functions/getGraph?fieldId=${fieldId}`)
      .then(({ data }) => {
        dispatch(setGraph(fieldId, data));
      });
  };
}

export function setGraph(fieldId: number, graphData: IGraphData[]) {
  return {
    type: SET_GRAPH,
    fieldId,
    graphData,
  };
}

export function setRandomQuestion(question: IQuestion) {
  return {
    type: SET_RANDOM_QUESTION,
    question,
  };
}

export function loadLabel(ipadId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(contentStatus(CONTENT_STATUS_LOADING));

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_REMOTE_API_URL}/api/explore/label/${ipadId}`
      );
      const { ipad, field, randomQuestion } = data;

      dispatch(setFields([field]));
      dispatch(setIPads([ipad]));
      dispatch(setRandomQuestion(randomQuestion));
      dispatch(contentStatus(CONTENT_STATUS_LOADED));
    } catch (e) {
      dispatch(contentStatus(CONTENT_STATUS_ERROR));
    }
  };
}

export function updateLabel(ipadId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_REMOTE_API_URL}/api/explore/label/${ipadId}`
      );
      const { ipad, field, randomQuestion } = data;

      dispatch(setFields([field]));
      dispatch(setIPads([ipad]));
      dispatch(setRandomQuestion(randomQuestion));
    } catch (e) {}
  };
}

export function loadFieldContent(fieldId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(contentStatus(CONTENT_STATUS_LOADING));

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_REMOTE_API_URL}/api/explore/field/${fieldId}`
      );
      const { ipads, fields, graph, fieldId: returnedId } = data;

      dispatch(setFields(fields));
      dispatch(setIPads(ipads));
      dispatch(setGraph(returnedId, graph));
      dispatch(contentStatus(CONTENT_STATUS_LOADED));
    } catch (e) {
      dispatch(contentStatus(CONTENT_STATUS_ERROR));
    }
  };
}

// export function setActiveField(activeField: IActiveField) {
//   return {
//     type: SET_ACTIVE_FIELD,
//     activeField,
//   };
// }
