import React from "react";
import ob04 from "./ob-04.svg";

const Onboarding04 = () => {
  return (
    <div className="onboarding-slide onboarding-slide-04">
      <div className="onboarding-image">
        <img src={ob04} alt="Onboarding, step 4" />
      </div>
      <div className="onboarding-copy">
        <p>This is how a Field grows.</p>
        <p>
          It’s a novel way to collectively discuss the topics in and around the
          artwork.
        </p>
      </div>
    </div>
  );
};

export default Onboarding04;
