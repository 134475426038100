import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../layout";
import { IAppState, IField, IQuestion } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "@reach/router";
import QRCode from "qrcode.react";
import { domain } from "../../common/settings";
import { useInterval } from "../../utils/useTime";
import { loadLabel, updateLabel } from "../../state/actions";
import { useIdleTimer } from "react-idle-timer";
import { Field } from "../../common/icons";
import { Helmet } from "react-helmet";

const REFRESH = 60000;

const Label = () => {
  const { ipadId } = useParams();
  const [largeText, setLargeText] = useState(false);
  const [mode, setMode] = useState("info");
  const [pulse] = useState<number>(REFRESH);
  const scrollPane = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const question: IQuestion = useSelector(
    (state: IAppState) => state.randomQuestion
  );
  const field: IField | null = useSelector((state: IAppState) => {
    return state.ipads[ipadId] && state.fields[state.ipads[ipadId].field_id]
      ? state.fields[state.ipads[ipadId].field_id]
      : null;
  });
  const iPad = useSelector((state: IAppState) => state.ipads[ipadId]);

  const { id = null } = field || {};
  const { id: qid = "" } = question || {};

  let qrCodeLink = id && qid && `${domain()}/fields/${id}?qid=${qid}`;
  let questionText = question?.question || "";

  // Reload the ipads every so often to make sure we have latest data
  useInterval(() => {
    ipadId && dispatch(updateLabel(ipadId));
  }, pulse);

  // Fetch a new random question initially and when the ID changes
  useEffect(() => {
    ipadId && dispatch(loadLabel(ipadId));
  }, [dispatch, ipadId]);

  // Reset text when idle
  useIdleTimer({
    timeout: 1000 * 60,
    onIdle: () => {
      if (largeText) {
        setLargeText(false);
      }
      if (mode !== "info") {
        setMode("info");
      }
      if (scrollPane.current) {
        scrollPane.current.scrollTop = 0;
      }
    },
  });

  return (
    <Layout showHeader={false} condition={!!field && !!iPad && !!question}>
      {field ? (
        <>
          <div className={`label-wrap ${largeText ? "large" : ""}`}>
            <div className="label">
              <div className="label-inner" ref={scrollPane}>
                <h1>{field.title}</h1>
                <h2>{field.contributors}</h2>
                {mode === "info" ? (
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: field.description,
                    }}
                  />
                ) : (
                  <div
                    className="about"
                    dangerouslySetInnerHTML={{
                      __html: field.bio,
                    }}
                  />
                )}
              </div>
            </div>
            {!field.hidden && (
              <div className="field">
                {questionText && (
                  <div className="question">
                    <p>"{questionText}"</p>
                    <div>Scan to engage with the AI who made this question</div>
                  </div>
                )}
                <div className="code">
                  <QRCode value={qrCodeLink || ""} />
                  <div className="field-anim">
                    <Field />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="buttons">
            <button
              className={`${mode === "info" ? "active" : ""}`}
              onClick={() => setMode("info")}
            >
              Info
            </button>
            <button
              className={`${mode === "bio" ? "active" : ""}`}
              onClick={() => setMode("bio")}
            >
              Bio
            </button>
            <button
              onClick={() => setLargeText(!largeText)}
              className={`large-text-toggle ${largeText ? "active" : ""}`}
            >
              Large Print
            </button>
          </div>
          <Helmet>
            <html className="label" />
            <title>{iPad.name}</title>
          </Helmet>
        </>
      ) : null}
    </Layout>
  );
};

export default Label;
