import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Layout } from "../layout";
import { IAppState, IField, IPrimerQA } from "../../types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "@reach/router";
import { saveField } from "../../state/actions";
import notify from "../../utils/notify";
import Field from "./Field";
import Primer from "./Primer";

// Default field pre loading...
const defaultField: IField = {
  id: -1,
  title: "",
  description: "",
  url: "",
  randomness: 0.7,
  primer_description: "",
  qa_order: "",
  qas: [],
  contributors: "",
  bio: "",
  active: 1,
  hidden: 0,
  image: "",
  exhibition: "",
};

type numberKey = "active" | "hidden";
type stringKEy = "title" | "description" | "url" | "primer_description" | "bio";

const ManageField = () => {
  const { fieldId } = useParams();
  const stateField: IField = useSelector(
    (state: IAppState) =>
      state.fields[fieldId] || {
        ...defaultField,
      }
  );
  const maxWordLimit = 1000;
  const [field, setField] = useState<IField>({ ...stateField });
  const [panel, setPanel] = useState("field");
  const [overMaxWords, setOverMaxWords] = useState(false)
  const dispatch = useDispatch();
  const hasField = stateField.id && stateField.id !== -1;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { name, value, checked },
    } = e;

    if (field) {
      const newField: IField = { ...field };

      if (name === "active" || name === "hidden") {
        const idx: numberKey = name as numberKey;
        newField[idx] = Number(checked);
      } else {
        const idx: stringKEy = name as stringKEy;
        let parse = value.replace(/[“”]+/g, '"')
        parse = parse.replace(/[‘’]+/g, "'")
        newField[idx] = parse as string;
      }
      if (name === 'primer_description') {
        const words = value.trim().split(/\s+/);
        const currentWordCount = words.length;
        console.log(currentWordCount)
        if( currentWordCount > maxWordLimit && overMaxWords === false) {
          setOverMaxWords(true)
        } else if (overMaxWords === true) {
          setOverMaxWords(false)
        }
      }
      

      setField(newField);
    }
  };

  // Save this persona
  const handleSave = (e: MouseEvent) => {
    e.preventDefault();
    notify("Saving Field...");
    dispatch(saveField(field));
  };

  const handleAddQA = (e: MouseEvent) => {
    e.preventDefault();
    const newField: IField = { ...field };
    newField.qas = newField.qas || [];
    newField.qas?.push({ question: "", answer: "", field_id: fieldId });
    setField(newField);
  };

  const updateQA = (index: number, data: IPrimerQA) => {
    const newField: IField = { ...field };
    if (newField.qas?.[index]) {
      newField.qas[index].answer = data.answer;
      newField.qas[index].question = data.question;
      setField(newField);
    }
  };

  const removeQA = (index: number) => {
    const newField: IField = { ...field };
    if (newField.qas && newField.qas[index]) {
      newField.qas.splice(index, 1);
      setField(newField);
    }
  };

  useEffect(() => {
    setField({ ...stateField });
  }, [setField, stateField]);

  return (
    <Layout className="manage-fields panes">
      {hasField ? (
        <div className="layout-full no-padding">
          <header className="subnav">
            <div>
              <button
                className={panel === "field" ? "active" : ""}
                onClick={() => setPanel("field")}
              >
                Field Details
              </button>
              <button
                className={panel === "primer" ? "active" : ""}
                onClick={() => setPanel("primer")}
              >
                Artist Commentary
              </button>
            </div>
            <div>
              <Link to="/manage/fields">Cancel</Link>
              <button onClick={handleSave} disabled={overMaxWords} className="big-button">
                Save
              </button>
            </div>
          </header>
          {panel === "field" ? (
            <Field field={field} 
              handleChange={handleChange} 
              handleAddQA={handleAddQA}
              updateQA={updateQA}
              removeQA={removeQA}
            />
          ) : (
            <Primer
              overMaxWords={overMaxWords}
              field={field}
              handleChange={handleChange}
            />
          )}
        </div>
      ) : null}
    </Layout>
  );
};

export default ManageField;
