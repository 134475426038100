import React from "react";

const Info = () => {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.92168 0.0149159C5.09785 0.157416 4.45918 0.774916 4.29629 1.58742C4.27637 1.68992 4.27168 1.75742 4.27051 1.93367C4.27051 2.18617 4.28809 2.31742 4.35254 2.52117C4.52715 3.07867 4.9373 3.43742 5.53496 3.55617C5.70137 3.58867 6.07988 3.58492 6.26035 3.54867C7.07012 3.38492 7.66777 2.78742 7.8166 1.99367C7.85293 1.79617 7.85293 1.47242 7.8166 1.28742C7.68301 0.621166 7.25176 0.176166 6.6002 0.0324159C6.44434 -0.00133412 6.0752 -0.0113341 5.92168 0.0149159Z"
        fill="black"
      />
      <path
        d="M4.62672 4.46735C4.37829 4.49235 4.30446 4.5011 4.16149 4.52735C3.35172 4.6736 2.52086 5.08235 1.77321 5.70235C1.55993 5.87985 1.38883 6.03985 1.08766 6.3486C0.390395 7.06235 0.0200821 7.5661 0.00133213 7.82485C-0.00569912 7.91985 0.0142228 7.97485 0.0821915 8.05485C0.170082 8.15485 0.26852 8.18985 0.369301 8.1536C0.497035 8.10735 0.677504 7.92735 1.10993 7.40985C1.74157 6.65235 2.01696 6.37735 2.32282 6.1986C2.48102 6.10485 2.59352 6.06735 2.72243 6.0661C2.81852 6.06485 2.83258 6.0686 2.88414 6.09985C3.02008 6.18485 3.07282 6.3611 3.02711 6.56985C3.01657 6.61485 2.61813 7.65485 2.14 8.88235C1.66305 10.1099 1.22008 11.2474 1.15797 11.4086C0.821645 12.2761 0.556801 13.1449 0.43727 13.7699C0.355238 14.1974 0.331801 14.6236 0.376332 14.8624C0.479457 15.4124 0.822816 15.7411 1.40993 15.8524C1.55524 15.8811 1.87985 15.8899 2.06852 15.8724C2.9861 15.7861 4.03024 15.2961 5.06969 14.4661C6.0025 13.7211 6.81813 12.7736 6.95289 12.2774C7.00329 12.0949 6.91422 11.8924 6.75602 11.8274C6.68454 11.7986 6.63883 11.8086 6.55329 11.8724C6.43024 11.9649 6.31657 12.0849 5.92164 12.5336C5.31813 13.2224 5.02868 13.5124 4.77086 13.6924C4.47672 13.8974 4.25875 13.9249 4.13454 13.7736C4.06891 13.6936 4.04782 13.6099 4.05602 13.4649C4.07125 13.2411 4.16266 12.9224 4.34782 12.4461C4.50602 12.0411 6.20875 7.3186 6.27672 7.0961C6.40094 6.68985 6.45368 6.39485 6.46305 6.0636C6.4736 5.71235 6.43727 5.48485 6.3318 5.2486C6.26383 5.0961 6.19821 4.99985 6.08454 4.8836C5.79743 4.59235 5.4025 4.45485 4.8611 4.4611C4.75446 4.46235 4.64899 4.46485 4.62672 4.46735Z"
        fill="black"
      />
    </svg>
  );
};

export default Info;
