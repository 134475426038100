import React from "react";

const Edit = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 13.4588V17H3.74948L14.8079 6.55589L11.0585 3.01472L0 13.4588ZM17.7075 3.81739C18.0975 3.4491 18.0975 2.85419 17.7075 2.4859L15.3679 0.276212C14.9779 -0.0920705 14.348 -0.0920705 13.9581 0.276212L12.1283 2.0043L15.8778 5.54548L17.7075 3.81739Z"
        fill="black"
      />
    </svg>
  );
};

export default Edit;
