import React from "react";
import { Link } from "@reach/router";
import { useSelector } from "react-redux";
import { ChevronRight } from "../../common/icons";
import { IAppState, IField, IFieldCollection } from "../../types";
import { Layout } from "../layout";

const Moderation = () => {
  const fields = useSelector((state: IAppState) =>
    Object.values(state.fields).reduce(
      (reduction: IFieldCollection, field: IField) => {
        if (field.active === 1) {
          reduction[field.id] = field;
        }
        return reduction;
      },
      {}
    )
  );

  return (
    <Layout>
      <div className="moderation content">
        <div className="title-bar">
          <h1>Moderation</h1>
        </div>
        <div className="edit-items">
          <div className="top-row">
            <span className="title">Field</span>
            <div className="measure">Unmoderated</div>
            <div className="measure">Moderated</div>
            <div className="measure">Rejected</div>
            <ChevronRight />
          </div>
          {Object.values(fields).map((field) => {
            return (
              <Link
                className="button"
                key={`field-${field.id}`}
                to={`/manage/moderation/${field.id}`}
              >
                <span className="title">{field.title}</span>
                <div className="measure unmoderated">
                  {field.unmoderated || 0}
                </div>
                <div className="measure moderated">{field.approved || 0}</div>
                <div className="measure rejected">{field.rejected || 0}</div>
                <ChevronRight />
              </Link>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Moderation;
