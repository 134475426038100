import React, { useState, useCallback, useEffect } from "react";
import { LimitedTextareaProps } from "../../types";

const LimitedTextarea = ({ value, onChange, limit }: LimitedTextareaProps) => {
  const [content, setContent] = useState(value.slice(0, limit));

  const setFormattedContent = useCallback(
    (text) => {
      const newVal = text.slice(0, limit);
      setContent(newVal);
      onChange(newVal);
    },
    [limit, onChange]
  );

  useEffect(() => {
    setContent(value.slice(0, limit));
  }, [limit, value]);

  return (
    <>
      <textarea
        onChange={(event) => setFormattedContent(event.target.value)}
        value={content}
      />
      <p className="remaining">
        {content.length}/{limit}
      </p>
    </>
  );
};

export default LimitedTextarea;
