// Check if localStorage is available or not. Required so builds don't break
const testLocalStorage = (): boolean => {
  const test: string = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const canUseLocalStorage: boolean = testLocalStorage();

export const localStorageSet = (key: string, val: any): void => {
  if (canUseLocalStorage) {
    localStorage.setItem(key, val);
  }
};

export const localStorageGet = (key: string, defaultVal?: any): any => {
  if (canUseLocalStorage) {
    return localStorage.getItem(key) || defaultVal;
  }

  return defaultVal;
};

export const localStorageRemove = (key: string): void => {
  if (canUseLocalStorage) {
    localStorage.removeItem(key);
  }
};
