import React from "react";

const FieldUnlocked = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21"
        cy="21"
        r="20"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
      <circle
        cx="21"
        cy="21"
        r="8"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
      <circle
        cx="21"
        cy="21"
        r="14"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="2 4"
      />
    </svg>
  );
};

export default FieldUnlocked;
