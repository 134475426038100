import React from "react";

const Spinner = ({ width = 144, height = 144, strokeDasharray = "2 4" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className="spinner"
    >
      <circle
        cx={width / 2}
        cy={height / 2}
        r={width / 2 - 0.5}
        stroke="black"
        strokeDasharray={strokeDasharray}
      />
    </svg>
  );
};

export default Spinner;
