import React from "react";

const Fields = ({ fill = "#000000" }) => {
  return (
    <svg
      width="209px"
      height="44px"
      viewBox="0 0 209 44"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="UI" stroke="none" strokeWidth="1" fill={fill} fillRule="evenodd">
        <g
          id="10.2”-iPad"
          transform="translate(-841.000000, -19.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <path
            d="M848.5,63 L848.5,45.3822715 L866,45.3822715 L866,38.9556787 L848.5,38.9556787 L848.5,29.4265928 L866,29.4265928 L866,23 L841,23 L841,63 L848.5,63 Z M882,63 L882,23 L873,23 L873,63 L882,63 Z M915,63 L915,56.5734072 L896.337423,56.5734072 L896.337423,45.3822715 L914.415133,45.3822715 L914.415133,38.9556787 L896.337423,38.9556787 L896.337423,29.4265928 L914.415133,29.4265928 L914.415133,23 L889,23 L889,63 L915,63 Z M947,63 L947,56.5734072 L929.632743,56.5734072 L929.632743,23 L922,23 L922,63 L947,63 Z M1000.12348,63 C1011.2513,63 1018.94174,57.1274238 1019,43 C1018.94174,28.8725762 1011.2513,23 1000.12348,23 L952,23 L952,63 L1000.12348,63 Z M999.595183,56 L960,56 L960,30 L999.595183,30 C1008.83601,30 1011,36.155102 1011,43 C1011,49.844898 1008.83601,56 999.595183,56 Z M1035.48757,63 C1044.66539,63 1050,58.6118598 1050,49.8355795 C1050,35.9595687 1028.66157,37.5013477 1028.66157,30.148248 C1028.66157,26.8274933 1031.12811,25.4043127 1034.56979,25.4043127 C1038.29828,25.4043127 1040.76482,27.6576819 1041.96941,30.9784367 L1049.19694,28.6657682 C1046.7304,22.4393531 1041.22371,19 1034.56979,19 C1027.39962,19 1021.20459,23.328841 1021.20459,30.9784367 C1021.20459,44.1428571 1042.54302,42.4824798 1042.54302,50.902965 C1042.54302,55.0539084 1039.50287,56.5956873 1035.54493,56.5956873 C1030.89866,56.5956873 1028.43212,53.9272237 1026.5392,49.8948787 L1020,52.8598383 C1022.35182,59.0862534 1028.71893,63 1035.48757,63 Z"
            id="FIELDS-Copy"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Fields;
