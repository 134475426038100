import * as d3 from "d3";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";

import "./DashedCircle";

import { Viewport } from "pixi-viewport";
import { centreGraph } from "./utils";
import { processData } from "./process";
import { IGraphData } from "../../types";

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

const DRAW_CYCLES = 50;
const GENERATION_RADIUS = 400;
const CENTER_COMPENSATION = 150;
const CENTER_COMPENSATION_PRESENTATION = 280;
const TRANSITION_DURATION = 2;
const TRANSITION_DELAY = 3;
const NODE_EASE = "elastic.out(1, 0.8)";
const GRAPH_EASE = "elastic.out(1, 0.5)";
const MAX_GEN = 6;

const ForceGraphRenderer = (
  container: HTMLDivElement,
  graphData: IGraphData[],
  fieldId: number,
  fieldImage: string,
  presentationMode: boolean = false
) => {
  console.log("new graph");
  let activeNode: number = -1;
  let activePath: string[] = [];

  let nodeGFX: any = {};
  let generations: any = {};
  let generationGFX: any = {};

  let numGenerations: number = 0;
  // let currentGen = 0;
  let firstRender = false;

  /**
   * Count generations
   * */
  Object.values(graphData).forEach((gd) => {
    numGenerations =
      gd.generation > numGenerations ? gd.generation : numGenerations;
  });

  /**
   * Setup animation timelines
   */
  const nodeTimeline = gsap.timeline().pause();
  const graphTimeline = gsap
    .timeline({
      onComplete: () => {
        const e = new CustomEvent("graphTimelineComplete");
        window.dispatchEvent(e);
      },
    })
    .pause();

  /**
   * Setup renderer
   */
  const containerRect = container.getBoundingClientRect();
  const height = containerRect.height;
  const width = containerRect.width;
  const tree_width = GENERATION_RADIUS + numGenerations * GENERATION_RADIUS;
  const tree_height = GENERATION_RADIUS + numGenerations * GENERATION_RADIUS;

  container.innerHTML = "";

  // Create App
  const app = new PIXI.Application({
    width: width,
    height: height,
    antialias: true,
    backgroundColor: 0xf5f6f0,
    resolution: window.devicePixelRatio,
    autoDensity: true,
  });

  container.appendChild(app.view);

  // Create viewport
  const viewport = new Viewport({
    screenWidth: width,
    screenHeight: height,
    worldWidth: width,
    worldHeight: height,
    passiveWheel: false,
    interaction: app.renderer.plugins.interaction,
  });

  // Set start point and zoom
  viewport.center = new PIXI.Point(0, 0);
  viewport.setZoom(presentationMode ? 1 : 0.5);

  app.stage.addChild(viewport);

  // Activate plugins
  viewport
    .pinch()
    .wheel()
    .decelerate()
    .clampZoom({
      minWidth: width,
      minHeight: height,
      maxWidth: tree_width * window.devicePixelRatio * 2,
      maxHeight: tree_height * window.devicePixelRatio * 2,
    })
    .drag();

  /**
   * Visual Implementation - initialise gfx for each node
   */
  let generationLines = new PIXI.Graphics();
  generationLines.interactive = false;

  let visualLinks = new PIXI.Graphics();
  visualLinks.interactive = false;

  let activeNodeGFX = new PIXI.Graphics();
  activeNodeGFX.interactive = false;

  viewport.addChild(generationLines);
  viewport.addChild(visualLinks);
  viewport.addChild(activeNodeGFX);

  // The UI needs to know when dragging starts
  viewport.on("drag-start", () => {
    window.dispatchEvent(new CustomEvent("viewportClick"));
  });

  viewport.on("wheel", () =>
    window.dispatchEvent(new CustomEvent("viewportClick"))
  );
  viewport.on("pinch-start", () =>
    window.dispatchEvent(new CustomEvent("viewportClick"))
  );

  const initNode = (node: any) => {
    let { type, size, mine = false, generation } = node.data;
    let { x, y } = node;
    let gfx = nodeGFX[node.id];
    const doAnimate = presentationMode || firstRender;

    if (!gfx) {
      /**
       ** Node currently has no gfx, so make them
       */
      gfx = new PIXI.Graphics();

      /**
       ** Set adjusted generation - answers and questions have same gemneration in DB
       */
      let gen = generation;
      if (gen > 1) {
        gen = gen * 2 - 2;
        gen = type === "question" ? gen + 1 : gen;
      }
      node.gen = gen;

      /**
       ** Figure out the min/max/median radii of each generations
       */
      if (!generations[gen]) {
        generations[gen] = {
          min: Number.MAX_VALUE,
          median: Number.MAX_VALUE / 2,
          max: 0,
          node,
        };
      }
      const dist = Math.sqrt(x * x + y * y);
      generations[gen].min = Math.min(dist, generations[gen].min);
      generations[gen].max = Math.max(dist, generations[gen].min);
      generations[gen].median =
        generations[gen].min +
        (generations[gen].max - generations[gen].min) / 2;

      /**
       ** Draw gfx
       ** Image for root and ellipse for node
       */
      if (type === "root") {
        gfx.interactive = true;

        if (fieldImage) {
          const img = PIXI.Sprite.from(fieldImage, {
            resolution: window.devicePixelRatio,
          });

          img.width = size * 2;
          img.height = size * 2;
          img.anchor.set(0.5);

          gfx.addChild(img);

          const mask = new PIXI.Graphics();
          mask.beginFill(0x000000);
          mask.drawEllipse(0, 0, size, size);
          gfx.addChild(mask);
          img.mask = mask;
        } else {
          gfx.beginFill(0x000000);
          gfx.drawEllipse(0, 0, size, size);
        }
      } else {
        gfx.lineStyle(1, 0x000000);

        if (type === "question") {
          gfx.beginFill(0x000000);
        } else {
          gfx.beginFill(mine ? 0x00ff00 : 0xffffff);
        }

        gfx.drawCircle(0, 0, size);
        gfx.endFill();
      }

      /**
       * Handle a touch or click on a node
       */
      const clickHandler = (e: any) => {
        let target = e.currentTarget;

        const event = new CustomEvent("graphClick", {
          detail: { ...node.data },
        });
        window.dispatchEvent(event);

        centreGraph(
          viewport,
          new PIXI.Point(
            target.transform.position._x,
            target.transform.position._y +
              (presentationMode
                ? CENTER_COMPENSATION_PRESENTATION
                : CENTER_COMPENSATION)
          ),
          {
            time: presentationMode ? 1500 : 500,
          }
        );

        activeNode = node.data.id;
        redrawNodes();
      };

      gfx.on("pointerup", clickHandler);
      gfx.interactive = true;
      gfx.buttonMode = true;
      gfx.hitArea = new PIXI.Circle(0, 0, size);

      /**
       * Set initial state and add animation
       */
      gfx.alpha = doAnimate ? 0 : 1;
      gfx.scale.set(doAnimate ? 0 : 1);
      gfx.position =
        node.parent && doAnimate
          ? new PIXI.Point(node.parent.x, node.parent.y)
          : new PIXI.Point(node.x, node.y);

      /**
       * If we haven't rendered before, we need to add the node animation to the timeline
       * For new nodes added after first render, we just animate them straight away
       */
      if (!firstRender) {
        nodeTimeline.to(
          gfx,
          {
            pixi: { x: node.x, y: node.y, scale: 1, alpha: 1 },
            duration: TRANSITION_DURATION + Math.random() * 1,
            ease: NODE_EASE,
          },
          TRANSITION_DELAY * Math.min(MAX_GEN, gen) + Math.random() * 1
        );
      } else {
        gsap.to(gfx, {
          pixi: { x: node.x, y: node.y, scale: 1, alpha: 1 },
          duration: TRANSITION_DURATION + Math.random() * 1,
          ease: NODE_EASE,
          delay: TRANSITION_DELAY,
        });
      }

      viewport.addChild(gfx);
      nodeGFX[node.id] = gfx;
    } else {
      /**
       * Existing node, animate position if it has moved
       */
      if (node.x !== gfx.position.x || node.y !== gfx.position.y) {
        gsap.to(gfx, {
          pixi: { x: node.x, y: node.y },
          duration: TRANSITION_DURATION,
          delay: TRANSITION_DELAY,
        });
      }
    }
  };

  /**
   * Redraw
   */
  const redrawGenerations = () => {
    Object.keys(generations).forEach((gen: string) => {
      const generation = generations[gen];

      if (!generationGFX[gen]) {
        generationGFX[gen] = new PIXI.Graphics();
        generationLines.addChild(generationGFX[gen]);
      }

      const gengfx = generationGFX[gen];
      gengfx.clear();
      gengfx.dashedCircle({
        radius: generation.median,
        dash: 10,
        gap: 8,
        lineColour: 0xbbbbbb,
      });
    });
  };

  const distance = (p1x: number, p1y: number, p2x: number, p2y: number) => {
    const dx = p1x - p2x;
    const dy = p1y - p2y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const redrawLinks = () => {
    visualLinks.clear();

    // Set the generation rings alpha according to the alpha of their sentinel node
    Object.keys(generationGFX).forEach((gen: string) => {
      const genNode = generations[gen].node;
      const ngfx = nodeGFX[genNode.id];
      const genGFX = generationGFX[gen];
      const parent = genNode.parent;
      const locationTolerance = 0.0001;

      if (
        Math.abs(genNode.x - ngfx.position.x) > locationTolerance ||
        Math.abs(genNode.y - ngfx.position.y) > locationTolerance
      ) {
        let alphaScale = ngfx.alpha;

        if (parent) {
          const pDist = distance(genNode.x, genNode.y, parent.x, parent.y);
          const currDist = distance(
            ngfx.position.x,
            ngfx.position.y,
            parent.x,
            parent.y
          );
          alphaScale = currDist / pDist;
        }
        genGFX.alpha = alphaScale;
      }
    });

    links.forEach((link: any) => {
      let { source, target } = link;
      const sourceGFX = nodeGFX[source.id],
        targetGFX = nodeGFX[target.id];
      const isActive =
        activePath.includes(source.id) && activePath.includes(target.id);
      visualLinks.lineStyle(
        isActive ? 2 : 1,
        isActive ? 0x000000 : 0x999999,
        Math.min(targetGFX.alpha, 1)
      );
      visualLinks.moveTo(sourceGFX.x, sourceGFX.y);
      visualLinks.lineTo(targetGFX.x, targetGFX.y);
    });

    visualLinks.endFill();
  };

  const redrawNodes = () => {
    activeNodeGFX.clear();

    nodes.forEach((node: any) => {
      const { x, y } = node;
      const { size, type } = node.data;
      const isActive = node.data.id === activeNode;

      if (type !== "root" && isActive) {
        const radius = size + 30;
        activeNodeGFX.x = x;
        activeNodeGFX.y = y;
        activeNodeGFX.dashedCircle({ radius, dash: 10, gap: 8 });
      }
    });
  };

  /**
   * Build the D3 graph
   */
  let nodes: any = null;
  let links: any = null;
  let simulation: any = null;

  const project = (x: number, y: number) => {
    const angle = ((x * 360) / tree_width / 180) * Math.PI,
      radius = y;
    return [radius * Math.cos(angle), radius * Math.sin(angle)];
  };

  const buildGraph = () => {
    // Create new tree map
    const tree = d3
      .tree()
      .separation(function (a, b) {
        return (a.parent === b.parent ? 10 : 2) / a.depth;
      })
      .size([tree_width, tree_height]);

    // Process data and create tree
    const hierarchy = processData(graphData, fieldId);
    const treeData = tree(hierarchy);
    nodes = treeData.descendants();
    links = treeData.links();

    // Project nodes into circle
    nodes.forEach((node: any) => {
      const [x, y] = project(node.x, node.y);
      node.x = x;
      node.y = y;
    });

    // Create force simlulation to 'shake' overlapping nodes apart
    // Run it for a fixed number of cycles
    simulation = d3.forceSimulation(nodes).force(
      "collision",
      d3.forceCollide().radius((d: any) => d.data.size)
    );
    simulation.stop();
    for (var i = 0; i < DRAW_CYCLES; ++i) simulation.tick();

    // Clear and reset animation timelines
    nodeTimeline.clear();
    nodeTimeline.pause().seek(0);
    graphTimeline.clear();
    graphTimeline.pause().seek(0);

    // Initialise every node. This creates gfx, adds animations and interaction handler
    nodes.forEach((node: any) => initNode(node));

    // Redraw gfx after update
    redrawGenerations();
    redrawNodes();

    // Create the graph timeline animation
    const genCount = Object.keys(generations).length;
    for (let i = 1; i < genCount && i <= MAX_GEN; i++) {
      const g = i === MAX_GEN ? genCount - 1 : i;
      const gen = generations[`${g}`];
      const scale = viewport.findFit(
        gen.median * 2 + 100,
        gen.median * 2 + 100
      );
      graphTimeline.to(
        viewport,
        {
          pixi: { scale },
          duration: TRANSITION_DELAY * 2,
          ease: i < MAX_GEN ? GRAPH_EASE : "expo.out",
        },
        TRANSITION_DELAY * i
      );
    }

    // Start the animations
    if (presentationMode && !firstRender) {
      setTimeout(() => {
        nodeTimeline.play();
        graphTimeline.play();
        gsap.to(viewport, {
          pixi: { rotation: 360 },
          duration: graphTimeline.totalDuration(),
        });
      }, 0);
    }

    firstRender = true;
  };

  buildGraph();

  /**
   * Add a new node
   */
  const addNode = (e: any) => {
    const newId = `a${e.detail.answer.id}`;

    buildGraph();

    const node = nodes.find((n: any) => n.id === newId);
    if (node) {
      centreGraph(viewport, new PIXI.Point(node.x, node.y));
    }
  };
  window.addEventListener("addGraphNode", addNode);

  /**
   * Listen for and set active path
   */
  window.addEventListener("graphPath", (e: any) => {
    activePath = e.detail.activePath;
    redrawNodes();
  });

  gsap.ticker.add(redrawLinks);
  /**
   * Return destroy and zoom funcs
   */
  return {
    destroy: () => {
      simulation.stop();
      Object.values(nodeGFX).forEach((gfx: any) => {
        nodeGFX.clear && nodeGFX.clear();
      });
      visualLinks.clear();
    },
    zoomTo: (searchId: number) => {
      activeNode = searchId;

      if (activeNode === -1) {
        activePath = [];
      }
      redrawNodes();

      const node = nodes.find((n: any) => n.id === searchId);
      if (node) {
        centreGraph(
          viewport,
          new PIXI.Point(
            node.x,
            node.y +
              (presentationMode
                ? CENTER_COMPENSATION_PRESENTATION
                : CENTER_COMPENSATION)
          ),
          {
            time: presentationMode ? 1500 : 500,
          }
        );
      }
    },
  };
};

export default ForceGraphRenderer;
