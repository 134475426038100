import React from "react";
import { Onboarding01, Onboarding02, Onboarding03, Onboarding04 } from ".";
import { IOnboardingProps } from "../../../types";

const Onboarding = ({
  onboardingStep,
  setOnboardingStep,
  completeOnboarding,
}: IOnboardingProps) => {
  const steps = [
    <Onboarding01 />,
    <Onboarding03 />,
    <Onboarding04 />,
    <Onboarding02 />,
  ];
  return (
    <div className="field-panel onboarding">
      {steps[onboardingStep]}
      <div className="onboarding-nav">
        <button
          className="onboarding-nav-prev"
          onClick={() =>
            onboardingStep > 0 && setOnboardingStep(onboardingStep - 1)
          }
        >
          Prev
        </button>
        <div className="onboarding-nav-progress">
          {onboardingStep + 1} - {steps.length}
        </div>
        <button
          className="onboarding-nav-next"
          onClick={() =>
            onboardingStep < steps.length - 1
              ? setOnboardingStep(onboardingStep + 1)
              : completeOnboarding()
          }
        >
          {onboardingStep === steps.length - 1 ? "Enter" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default Onboarding;
