import axios from "axios";
import dayjs from "dayjs";
import React, { MouseEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FieldClose, Spinner } from "../../../common/icons";
import { DATE_FORMAT, LOCALSTORAGE_VERSION } from "../../../common/settings";
import {
  IAppState,
  IGraphData,
  IGraphDataCollection,
  IQuestionModalProps,
} from "../../../types";
import { localStorageGet, localStorageSet } from "../../../utils/local-storage";
import { LimitedTextarea } from "../../limited-textarea";

interface IPanelProps {
  show: boolean;
}

interface IAnswerPanelProps extends IPanelProps {
  question: IGraphData;
  fieldId: number;
  exitMode: (mode: string) => void;
}

const QuestionModal = ({
  toggleModal,
  qid,
  fieldId,
  setNewQuestion,
}: IQuestionModalProps) => {
  const [mode, setMode] = useState("question");
  const field = useSelector((state: IAppState) => state.fields[fieldId]);
  const graph = useSelector(
    (state: IAppState) =>
      state.graph[fieldId] &&
      state.graph[fieldId].reduce(
        (obj: IGraphDataCollection, item: IGraphData) => {
          obj[item.id] = item;
          return obj;
        },
        {}
      )
  );

  useEffect(() => {
    setMode("question");
  }, [qid]);

  const question = graph[qid] || null;
  console.log(question.generation);
  const QuestionPanel = ({ show }: IPanelProps) =>
    show && question ? (
      <div className="question-panel">
        <nav>
          <button onClick={() => toggleModal("controls")}>
            <FieldClose /> Back to Field
          </button>
        </nav>
        <div className="panel-content">
          <h4>{field.title}</h4>
          <div className="panel-question">
            {question.question.substr(0, 140)}
          </div>
          <div className="question-controls">
            <button onClick={() => setMode("answer")}>Answer</button>
            <button className="refresh" onClick={setNewQuestion}>
              Refresh
            </button>
          </div>
          <div className="question-meta">
            <div>
              CREATED BY{" "}
              {question.path.indexOf(".") !== -1
                ? "AN AI CALLED GPT-3.5"
                : "A HUMAN"}
            </div>
            <div>
              <span>ADDED TO FIELD</span>
              <span>{dayjs(question.created_at).format(DATE_FORMAT)}</span>
            </div>
            <div>
              <span>GENERATION</span>
              <span>{question.generation}</span>
            </div>
            <div>
              <span>ANSWERED</span>
              <span>{question.size} TIMES</span>
            </div>
          </div>
        </div>
      </div>
    ) : null;

  const AnswerPanel = ({
    show,
    question,
    fieldId,
    exitMode,
  }: IAnswerPanelProps) => {
    const [answer, setAnswer] = useState("");
    const [isSending, setIsSending] = useState(false);
    const ANSWER_LIMIT = 140;

    const sendAnswer = async (e: MouseEvent) => {
      e.preventDefault();
      setIsSending(true);
      try {
        const { data } = await axios.post("/.netlify/functions/addAnswer", {
          answers: {
            field_id: fieldId,
            answer,
            question_id: question.id,
          },
        });

        const aData = {
          id: data.id,
          qid: data.question_id,
          answer: data.answer,
          created_at: data.created_at,
          generation: question.generation + 1,
        };

        // add to ls
        const myAnswers = JSON.parse(localStorageGet("answers", "{}"));
        if (!myAnswers[fieldId]) myAnswers[fieldId] = [];
        myAnswers[fieldId].push(aData);
        myAnswers.version = LOCALSTORAGE_VERSION;
        localStorageSet("answers", JSON.stringify(myAnswers));

        // alert listeners of the new item
        window.dispatchEvent(
          new CustomEvent("addGraphNode", {
            detail: {
              answer: aData,
            },
          })
        );

        // clean up and exit
        setIsSending(false);
        exitMode("controls");
      } catch (e) {
        setIsSending(false);
      }
    };

    const handleChange = (value:any) => {      
      let parse = value.replace(/[“”]+/g, '"')
      parse = parse.replace(/[‘’]+/g, "'")
      setAnswer(parse)
    };
    
    

    return show ? (
      <div className={`answer-panel ${isSending ? "sending" : ""}`}>
        <div className="panel-wrap">
          <nav>
            <button onClick={() => setMode("question")}>
              <FieldClose /> Back
            </button>
          </nav>
          <div className="panel-content">
            <div className="panel-question">
              {question.question.substr(0, 140)}
            </div>
            <form>
              <LimitedTextarea
                value={answer}
                limit={ANSWER_LIMIT}
                onChange={handleChange}
              />
              <button onClick={sendAnswer}>Send</button>
            </form>
          </div>
        </div>
        {isSending && (
          <div className="loader">
            <Spinner />
            <div>Submitting answer</div>
          </div>
        )}
      </div>
    ) : null;
  };

  return (
    <div className="field-question">
      <QuestionPanel show={mode === "question"} />
      <AnswerPanel
        show={mode === "answer"}
        question={question}
        fieldId={fieldId}
        exitMode={toggleModal}
      />
    </div>
  );
};

export default QuestionModal;
