import React, { ChangeEvent, useRef, useState } from "react";
import { IField } from "../../types";
import QRCode from "qrcode.react";
import { domain } from "../../common/settings";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import { QA } from "../qa";

const modules = {
  toolbar: [["bold", "italic", "clean"]],
};

const Field = ({
  field,
  handleChange,
  handleAddQA,
  updateQA,
  removeQA
}: {
  field: IField;
  handleChange: any;
  handleAddQA: any;
  updateQA: any;
  removeQA: any;
}) => {
  const [imageCB, setImageCB] = useState(new Date().getTime());
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];

    if (file) {
      try {
        const { data } = await axios.get(`/.netlify/functions/generateSAS`);
        const blobServiceClient = new BlobServiceClient(
          `https://fieldsapi.blob.core.windows.net?${data.sas}`
        );
        const containerName = `fields-storage`;
        const containerClient =
          blobServiceClient.getContainerClient(containerName);
        const blobName = `field-${field.id}.png`;
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);
        const options = { blobHTTPHeaders: { blobContentType: file.type } };
        await blockBlobClient.uploadData(file, options);

        handleChange({
          currentTarget: {
            name: "image",
            value: `https://fieldsapi.blob.core.windows.net/${containerName}/${blobName}`,
          },
        });

        setImageCB(new Date().getTime());

        const currentFileInput = fileInput.current;
        if (currentFileInput) {
          currentFileInput.value = "";
        }
      } catch (e) {}
    }
  };

  return (
    <div className="panes">
      <div className="pane field-form scroll">
        <div className="pane-inner-scroll">
          <h1>{field.title}</h1>
          <p style={{fontSize: '1.4rem', paddingBottom: '1rem'}}>Field Details are viewable to the the public via the Digital Labels. This information is also used to prime the AI with specific knowledge and context to compose interesting, thought provoking questions.</p>
          <p className="field-url">{field.url || ""}</p>
          <form>
            <div className="form-row">
              <label>Title</label>
              <input
                value={field.title || ""}
                type="text"
                name="title"
                onChange={handleChange}
              />
            </div>
            <div className="form-row">
              <label>URL</label>
              <input
                value={field.url || ""}
                type="text"
                name="url"
                onChange={handleChange}
              />
            </div>
            <div className="form-row">
              <label>Exhibition</label>
              <input
                value={field.exhibition || ""}
                type="text"
                name="exhibition"
                onChange={handleChange}
              />
            </div>
            <div className="form-row">
              <label>Contributors</label>
              <input
                value={field.contributors || ""}
                type="text"
                name="contributors"
                onChange={handleChange}
              />
            </div>
            <div className="form-row">
              <label>Description</label>
              <ReactQuill
                value={field.description}
                modules={modules}
                onChange={(value) =>
                  handleChange({
                    currentTarget: { name: "description", value },
                  })
                }
              />
            </div>
            <div className="form-row">
              <label>Artist bio</label>
              <ReactQuill
                value={field.bio}
                modules={modules}
                onChange={(value) =>
                  handleChange({
                    currentTarget: { name: "bio", value },
                  })
                }
              />
            </div>
            <div className="form-row">
              <h4>Seed Questions</h4>
              <p style={{fontSize: '1.4rem', paddingBottom: '1rem'}}>These will be the questions that will start off the field. A good number of questions is 6. But only 1 question is required to start.</p>
              {field.qas &&
                field.qas.map((qa, i) => {
                  return (
                    <QA
                      key={`qa-${qa.id}-${i}`}
                      index={i}
                      data={qa}
                      updateQA={updateQA}
                      removeQA={removeQA}
                    />
                  );
                })}
              <button onClick={handleAddQA}>+ Add New</button>
            </div>
            <div className="form-row image">
              <label>Field image (PNG only)</label>
              {field.image && (
                <img src={`${field.image}?cb=${imageCB}`} alt={field.title} />
              )}
              <label className="file-input-label">
                <input
                  type="file"
                  onChange={handleFile}
                  accept="image/png"
                  ref={fileInput}
                />
                <span>{field.image ? "Replace Image" : "Upload Image"}</span>
              </label>
            </div>
            <div className="form-row">
              <label className="checkbox">
                <input
                  name="hidden"
                  type="checkbox"
                  checked={Boolean(field.hidden || 0)}
                  onChange={handleChange}
                />{" "}
                <span>
                  <strong>Hide field</strong> on wall label for works in the
                  current show that don't require a Field
                </span>
              </label>
            </div>
            <div className="form-row">
              <label className="checkbox">
                <input
                  name="active"
                  type="checkbox"
                  checked={Boolean(field.active || 0)}
                  onChange={handleChange}
                />{" "}
                <span>
                  <strong>Activate Field</strong> - uncheck this for works in
                  the current show that don't need a Field. The Field, along
                  with it's answers and questions will be removed from the API
                  (but not deleted)
                </span>
              </label>
            </div>
          </form>
        </div>
      </div>
      <div className="pane pane-alt pane-narrow">
        <p>This QR code will take you to this field: <a target='_blank' rel='noreferrer' href={`${domain()}/fields/${field.id}`}>{domain()}/fields/{field.id}</a></p>
        <QRCode value={`${domain()}/fields/${field.id}`} size={500} />
      </div>
    </div>
  );
};

export default Field;
