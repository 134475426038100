import React from "react";

const Tick = ({ fill = "#000000" }) => {
  return (
    <svg
      width="41"
      height="31"
      viewBox="0 0 41 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2499 24.4501L3.7999 15.0001L0.649902 18.1501L13.2499 30.7501L40.2499 3.7501L37.0999 0.600098L13.2499 24.4501Z"
        fill={fill}
      />
    </svg>
  );
};

export default Tick;
