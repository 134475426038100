export const INIT_CONTENT = "INIT_CONTENT";
export const CONTENT_STATUS = "CONTENT_LOADING";

export const CONTENT_STATUS_ERROR = -1;
export const CONTENT_STATUS_LOADED = 1;
export const CONTENT_STATUS_LOADING = 0;

export const LOAD_QUESTIONS = "LOAD_QUESTIONS";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_QUESTION = "SET_QUESTION";

export const SAVE_FIELD = "SAVE_FIELD";
export const SET_FIELDS = "SET_FIELDS";
export const UPDATE_FIELD = "UPDATE_FIELD";

export const SET_IPADS = "SET_IPADS";
export const UPDATE_IPAD = "UPDATE_IPAD";

export const ADD_QA = "ADD_QA";
export const UPDATE_QA = "UPDATE_QA";

export const LOAD_GRAPH = "LOAD_GRAPH";
export const SET_GRAPH = "SET_GRAPH";

export const SET_RANDOM_QUESTION = "SET_RANDOM_QUESTION";

export const SET_ACTIVE_FIELD = "SET_ACTIVE_FIELD";
