import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
const win: any = window;

export default createStore(
  rootReducer,
  {},
  compose(
    applyMiddleware(thunk),
    win.devToolsExtension ? win.devToolsExtension() : (f: any) => f
  )
);
