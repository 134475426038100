import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../common/icons";
import { initContent } from "../../state/actions";
import { CONTENT_STATUS_ERROR } from "../../state/actionTypes";
import { IAppState, IContentLoaderProps } from "../../types";

const ContentLoader = ({
  statusText = "Loading Fields",
}: IContentLoaderProps) => {
  const dispatch = useDispatch();
  const contentStatus = useSelector((state: IAppState) => state.contentStatus);
  // const contentStatus = CONTENT_STATUS_ERROR;

  return (
    <div className="content-loader">
      {contentStatus === CONTENT_STATUS_ERROR ? (
        <>
          <p>There was an error loading the field data</p>
          <button onClick={() => dispatch(initContent())}>Try Again</button>
        </>
      ) : (
        <>
          <Spinner />
          {statusText && <div>{statusText}</div>}
        </>
      )}
    </div>
  );
};

export default ContentLoader;
