import React, { useEffect } from "react";

import { ForceGraphRenderer } from "./";
import { IAppState, IForceGraphProps } from "../../types";
import { useSelector } from "react-redux";

const ForceGraph = ({
  fieldId,
  presentationMode = false,
}: IForceGraphProps) => {
  const containerRef = React.useRef(null);
  const graph = useSelector((state: IAppState) => state.graph[fieldId]);
  const { image } = useSelector((state: IAppState) => state.fields[fieldId]);

  useEffect(() => {
    let destroyFn;

    const containerDiv = containerRef.current;
    if (containerDiv !== null && !!graph) {
      const { destroy, zoomTo } = ForceGraphRenderer(
        containerDiv,
        graph,
        fieldId,
        image,
        presentationMode
      );
      destroyFn = destroy;
      window.forceGraphFocus = zoomTo;
    }

    return destroyFn;
  }, [fieldId, graph, image, presentationMode]);

  return (
    <div className="field-vis">
      <div ref={containerRef} className="force-graph-container" />
    </div>
  );
};

export default ForceGraph;
