import React, { useEffect, useState } from "react";
import { IConversationSliderProps } from "../../../types";
import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { DATE_FORMAT, MAX_ANSWER_LENGTH } from "../../../common/settings";

const ConversationSlider = ({
  conversation,
  activeId,
  setMode,
  setQuestion,
  presentationMode = false,
}: IConversationSliderProps) => {
  const activeType = activeId.charAt(0) === "q" ? "question" : "answer";
  const activeDBId = parseInt(activeId.substr(1));
  const activeIndex = conversation.findIndex(
    (chat) => chat.id === activeDBId && chat.type === activeType
  );
  const [swiper, setSwiper] = useState<any>();

  useEffect(() => {
    const handleGraphClick = () => {
      setMode("controls");
    };
    window.addEventListener("viewportClick", handleGraphClick);

    return () => window.removeEventListener("viewportClick", handleGraphClick);
  }, [setMode]);

  useEffect(() => {
    const newActive = conversation.findIndex(
      (chat) => chat.id === activeDBId && chat.type === activeType
    );

    if (swiper && swiper.slides && newActive !== -1) {
      swiper.slideTo(newActive);
    }
  }, [activeDBId, activeType, conversation, swiper]);

  return conversation.length ? (
    <div className="field-conversation">
      <Swiper
        onSwiper={(s) => {
          setSwiper(s);
        }}
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={0}
        className="field-conversation-carousel"
        initialSlide={activeIndex !== -1 ? activeIndex : 0}
        speed={presentationMode ? 1000 : 300}
        onSlideChange={(e) => {
          const chat = conversation[e.activeIndex];
          const gid = `${chat.type === "question" ? "q" : "a"}${chat.id}`;
          window.forceGraphFocus && window.forceGraphFocus(gid);
        }}
      >
        {conversation.map((chat) => {
          if (chat.type === "answer") {
            return (
              <SwiperSlide key={`answer-${chat.id}`}>
                <div className="convo-tile answer">
                  <div className="card-content">
                    <span>"{chat.content.substr(0, MAX_ANSWER_LENGTH)}"</span>
                  </div>
                  <div className="meta">
                    <span>{chat.mine ? "YOUR" : "VISITOR"} ANSWER</span>
                    <span>
                      SUBMITTED {dayjs(chat.created_at).format(DATE_FORMAT)}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            );
          } else {
            let id = chat.id;
            return (
              <SwiperSlide key={`question-${id}`}>
                <div className="convo-tile question">
                  <div className="card-content">
                    <span>"{chat.content.substr(0, 140)}"</span>
                    <button
                      onClick={() => {
                        setMode("question");
                        setQuestion(id);
                      }}
                    >
                      Tap to answer
                    </button>
                  </div>
                  <div className="meta">
                    <span>GENERATION {chat.generation || 0}</span>
                    <span>
                      CREATED BY{" "}
                      {chat.path?.indexOf(".") !== -1
                        ? "AN AI CALLED GPT-3.5"
                        : "A HUMAN"}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            );
          }
        })}
      </Swiper>
    </div>
  ) : null;
};

export default ConversationSlider;
