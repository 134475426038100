import React from "react";

const Field = () => {
  return (
    <svg
      width="449"
      height="449"
      viewBox="0 0 449 449"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="field-svg-wrap"
    >
      <path
        d="M196.707 300.951C238.553 316.091 284.749 294.442 299.89 252.596C315.03 210.75 293.381 164.554 251.535 149.414C209.689 134.274 163.493 155.923 148.353 197.768C133.213 239.614 154.862 285.81 196.707 300.951Z"
        stroke="black"
        strokeWidth="2"
        strokeDasharray="4 8"
        strokeOpacity="0"
        className="field-svg field-svg-01"
      />
      <path
        d="M186.78 327.895C244.72 348.858 308.684 318.882 329.648 260.942C350.611 203.002 320.636 139.038 262.695 118.074C204.755 97.1109 140.791 127.087 119.828 185.027C98.8641 242.967 128.84 306.931 186.78 327.895Z"
        stroke="black"
        strokeOpacity="0"
        strokeWidth="2"
        strokeDasharray="4 8"
        className="field-svg field-svg-02"
      />
      <path
        d="M174.724 357.767C248.759 384.554 330.491 346.252 357.277 272.217C384.064 198.182 345.762 116.45 271.727 89.6635C197.692 62.8769 115.96 101.179 89.1734 175.214C62.3868 249.249 100.689 330.981 174.724 357.767Z"
        stroke="black"
        strokeOpacity="0"
        strokeWidth="2"
        strokeDasharray="4 8"
        className="field-svg field-svg-03"
      />
      <path
        d="M165.297 387.677C255.483 420.308 355.046 373.649 387.677 283.463C420.307 193.276 373.649 93.7132 283.462 61.0827C193.276 28.4522 93.7128 75.1106 61.0823 165.297C28.4519 255.484 75.1103 355.047 165.297 387.677Z"
        stroke="black"
        strokeOpacity="0"
        strokeWidth="2"
        strokeDasharray="4 8"
        className="field-svg field-svg-04"
      />
    </svg>
  );
};

export default Field;
