import React, { useEffect, useState } from "react";
import sgLogo from "../../../common/icons/sg.png";
import fieldImg from "../field-img.png";
import { Crescent, DottedRect, Fields, Spinner } from "../../../common/icons";

const Intro = () => {
  const [imgLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = fieldImg;
  }, []);

  return (
    <div className="field-panel field-intro">
      <div className="intro-content">
        <div
          className={`field-intro-bg-img ${imgLoaded ? "loaded" : ""}`}
          style={{ backgroundImage: `url(${fieldImg})` }}
        ></div>
        <DottedRect />
        <Crescent />
        <Spinner width={320} height={320} />
        <div className="load-text">Loading...</div>
      </div>
      <footer>
        <Fields />
        <img src={sgLogo} alt="Science Gallery Logo" />
      </footer>
    </div>
  );
};

export default Intro;
