import { combineReducers } from "redux";
import {
  SET_QUESTIONS,
  SET_QUESTION,
  SET_FIELDS,
  SET_IPADS,
  ADD_QA,
  UPDATE_QA,
  UPDATE_FIELD,
  UPDATE_IPAD,
  CONTENT_STATUS,
  SET_GRAPH,
  SET_RANDOM_QUESTION,
} from "./actionTypes";
import {
  IField,
  IFieldAction,
  IFieldCollection,
  IQuestionAction,
  IFieldQuestionCollection,
  IIPadAction,
  IIPadCollection,
  IContentStatusAction,
  IGraphAction,
  IGraphCollection,
  IQuestionCollection,
  IQuestion,
  IRandomQuestionAction,
} from "../types";

function questions(
  state: IFieldQuestionCollection = {},
  action: IQuestionAction
) {
  switch (action.type) {
    case SET_QUESTIONS:
      const { fieldId, questions } = action;

      if (fieldId && questions) {
        const stateQuestions = state[fieldId] || {};
        const newQuestions: IQuestionCollection = {};
        questions.forEach(
          (question) => (newQuestions[question.id] = { ...question })
        );
        return {
          ...state,
          [fieldId]: { ...stateQuestions, ...newQuestions },
        };
      }
      return state;
    case SET_QUESTION:
      const { question } = action;
      const { field_id } = question;

      if (question && field_id) {
        const stateQuestions: IQuestionCollection = state[field_id] || {};

        return {
          ...state,
          [field_id]: { ...stateQuestions, [question.id]: { ...question } },
        };
      }
      return state;
    default:
      return state;
  }
}

function fields(state: IFieldCollection = {}, action: IFieldAction) {
  const newState: IFieldCollection = { ...state };

  switch (action.type) {
    case SET_FIELDS:
      const newFields: IFieldCollection = {};
      action.fields?.forEach((field: IField) => {
        newFields[field.id] = { ...field };
      });
      return { ...newState, ...newFields };

    case UPDATE_FIELD:
      if (action.field) {
        newState[action.field.id] = action.field;
      }
      return newState;

    case ADD_QA:
      if (action.qa) {
        const newField = newState[action.qa.field_id];
        if (!newField.qas) {
          newField.qas = [];
        } else {
          newState[action.qa.field_id].qas?.push({ ...action.qa });
        }
      }
      return newState;

    case UPDATE_QA:
      if (action.qa && action.index !== undefined) {
        const newField = newState[action.qa.field_id];

        if (!newField.qas) {
          newField.qas = [];
        }
        newField.qas[action.index] = { ...action.qa };
      }
      return newState;

    default:
      return state;
  }
}

function ipads(state: IIPadCollection = {}, action: IIPadAction) {
  switch (action.type) {
    case SET_IPADS:
      if (action.ipads) {
        const allIpads: IIPadCollection = { ...state };
        for (const ipad of action.ipads) {
          allIpads[ipad.id] = ipad;
        }
        return allIpads;
      }
      return state;
    case UPDATE_IPAD:
      if (action.ipad) {
        const updateIpads: IIPadCollection = { ...state };
        const { ipad } = action;
        updateIpads[ipad.id] = ipad;
        return updateIpads;
      }
      return state;
    default:
      return state;
  }
}

function contentStatus(state: number = 0, action: IContentStatusAction) {
  switch (action.type) {
    case CONTENT_STATUS:
      return action.contentStatus;
    default:
      return state;
  }
}

function graph(state: IGraphCollection = {}, action: IGraphAction) {
  switch (action.type) {
    case SET_GRAPH:
      const data = action.graphData || [];
      return { ...state, [action.fieldId]: [...data] };
    default:
      return state;
  }
}

function randomQuestion(
  state: IQuestion | null = null,
  action: IRandomQuestionAction
) {
  switch (action.type) {
    case SET_RANDOM_QUESTION:
      const { question } = action;
      return { ...question };
    default:
      return state;
  }
}

const conversationApp = combineReducers({
  questions,
  fields,
  ipads,
  contentStatus,
  graph,
  randomQuestion,
});

export default conversationApp;
