import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

import "./styles/style.scss";

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_JWT_DOMAIN || ""}
    clientId={process.env.REACT_APP_JWT_CLIENT_ID || ""}
    audience={process.env.REACT_APP_JWT_AUDIENCE}
    redirectUri={`${window.location.origin}/manage/fields`}
    cacheLocation={"localstorage"}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
