import React, { MouseEvent } from "react";
import { useSelector } from "react-redux";
import {
  FieldIconSmall,
  FieldLock,
  Fields,
  FieldUnlocked,
} from "../../../common/icons";
import {
  IAppState,
  IField,
  IFieldCollection,
  IMenuProps,
} from "../../../types";
import { localStorageGet } from "../../../utils/local-storage";
import sgWhite from "./sg-white.png";

const Menu = ({ currentId, handleClose }: IMenuProps) => {
  const fields = useSelector((state: IAppState) =>
    Object.values(state.fields).reduce(
      (reduction: IFieldCollection, field: IField) => {
        if (field.active === 1 && field.hidden !== 1) {
          reduction[field.id] = field;
        }
        return reduction;
      },
      {}
    )
  );

  return (
    <div className="fields-index-wrap">
      <div className="fields-index">
        <div className="fields-index-scroll">
          {Object.keys(fields).map((fieldId: string) => {
            const field: IField = fields[parseInt(fieldId)];
            const active = localStorageGet(`field-${fieldId}`, false);
            const Tag = (active ? `a` : `div`) as keyof JSX.IntrinsicElements;

            return (
              <Tag
                className={`fields-index-field ${active ? "active" : ""}`}
                key={`field-index-field-${fieldId}`}
                href={`/fields/${fieldId}`}
                onClick={(e: MouseEvent) => {
                  if (parseInt(fieldId) === currentId) {
                    e.preventDefault();
                    handleClose();
                  }
                }}
              >
                <div className="icon">
                  {active ? <FieldUnlocked /> : <FieldLock />}
                </div>
                <div className="info">
                  <h3>{field.title}</h3>
                  <h4>{field.contributors}</h4>
                </div>
              </Tag>
            );
          })}
        </div>
      </div>
      <footer className="fields-index-footer">
        <div className="top-bar">
          <div>👀 Scan QR code on the artwork's label to unlock its Field</div>
          <FieldIconSmall />
        </div>
        <div className="bottom-bar">
          <Fields fill="#fff" />
          <img src={sgWhite} alt="Science Gallery logo - white" />
        </div>
      </footer>
    </div>
  );
};

export default Menu;
