import React, { MouseEvent, useEffect, useState } from "react";
import { IField } from "../../types";
import axios from "axios";
import { Refresh } from "../../common/icons";

const PrimerForm = ({
  field,
  handleChange,
  overMaxWords
}: {
  field: IField;
  handleChange: any;
  overMaxWords: any
}) => {
  const [demoAnswer, setDemoAnswer] = useState("");
  const [randomQuestion, setRandomQuestion] = useState("");
  // const [generated, setGenerated] = useState("");
  const [conversation, setConversation] = useState<any[]>([])

  const getRandomQuestion = async () => {
    const { data } = await axios.get(
      `/.netlify/functions/getRandomQuestion?field_id=${field.id}`
    );
    setRandomQuestion(data.question);
  };

  const randomise = (e: MouseEvent) => {
    e.preventDefault();
    getRandomQuestion();
  };

  const generateQuestion = async (e: MouseEvent) => {
    e.preventDefault();
    if (!demoAnswer.trim()) return;

    const testPrimer = buildPrimer();
    console.log('testPrimer', testPrimer)
    const { data } = await axios.post(`/.netlify/functions/generateQuestionV2`, {
      primer: testPrimer,
      randomness: field.randomness,
    });
    
    const tmp_conversation = [...conversation]
    tmp_conversation.push({Q: randomQuestion, A: demoAnswer})

    setConversation(tmp_conversation)
    console.log(data)
    const parseData = data.split('Q: ')[1]
    // setGenerated(parseData);
    setRandomQuestion(parseData)
    setDemoAnswer('')
  };

  

  const buildPrimer = () => {
    return {
      title: field.title?.trim(),
      artist: field.contributors?.trim(),
      description: field.description?.trim(),
      bio: field.bio?.trim(),
      questions: `Q: ${randomQuestion?.trim()} \n \n Visitor's answer: ${demoAnswer?.trim()}`,
      context: `${field.primer_description}`
    }
  }

  useEffect(() => {
    getRandomQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="panes">
      <div className="pane field-form scroll">
        <div className="pane-inner-scroll">
          <h1>{field.title}</h1>
          <p className="field-url">{field.url || ""}</p>
          <form>
            
            <div className="form-row">
              <label>Artist Commentary</label>
              <div style={{fontSize: '1.4rem', paddingBottom: '1rem'}}>Write some context for the questions to be generated. Examples of what you can include are: 
              <ul>
                <li>
                  Tone
                </li>
                <li>
                  Background information of the artist or artwork
                </li>
                <li>
                  Themes you may want to explore in questions generated.
                </li>
              </ul>
              This won't be for the public to view but will inform the types of questions that will be created.
              </div>
              {overMaxWords && (
                <p style={{ color: 'red',fontSize: '1.4rem', paddingBottom: '1rem' }}>Word count exceeds the limit of 1000 words.</p>
              )}
              <textarea
                name="primer_description"
                onChange={handleChange}
                value={field.primer_description || ""}
              />
            </div>
            <div className="form-row">
              <label>Randomness</label>
              <input
                type="number"
                step="0.05"
                name="randomness"
                value={field.randomness || 0.7}
                onChange={handleChange}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="pane pane-alt pane-narrow scroll">
        <div className="pane-inner-scroll">
          <h3 className="pane-section-heading">Test Prompt</h3>
          <p style={{fontSize: '1.4rem', paddingBottom: '1rem'}}>Once you have saved your artwork. You can test how succesfull the Field might be in creating questions from vistor's responses. See where the 'Generations' of questions might lead you to.</p>
          <form>
            <div className="form-row">
              <div className="button-label">
                <label>Question</label>
                <button onClick={randomise} className="refresh-button">
                  <Refresh /> Refresh
                </button>
              </div>
              <textarea
                className="primer-test-field"
                name="demo-question"
                value={randomQuestion}
                onChange={(e) => setRandomQuestion(e.currentTarget.value)}
              />
            </div>
            <div className="form-row">
              <label>Answer</label>
              <textarea
                className="primer-test-field"
                name="demo-answer"
                value={demoAnswer}
                onChange={(e) => setDemoAnswer(e.currentTarget.value)}
              />
            </div>
            <div className="form-row">
              <button
                onClick={generateQuestion}
                disabled={demoAnswer.trim() === ""}
              >
                Generate
              </button>
            </div>
            {conversation.length > 0 &&
            conversation.map((con, i) => {
              return (
                <div key={i}>
                  <p style={{fontWeight: 'bold'}}>
                    Q: {con.Q}
                    
                  </p>
                  <p>
                    A: {con.A}
                  </p>
                </div>
                
              )
            })

            }
            {/* {generated && (
              <div className="form-row result">
                <label>Result</label>
                <p className="primer-test-response">{`${generated}`}</p>
              </div>
            )} */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default PrimerForm;
