import React from "react";
import ob02 from "./ob-02.svg";

const Onboarding02 = () => {
  return (
    <div className="onboarding-slide onboarding-slide-02">
      <div className="onboarding-image">
        <img src={ob02} alt="Onboarding, step 2" />
      </div>
      <div className="onboarding-copy">
        <p>
          Your activity is recorded anonymously in your browser. You may clear
          this at any time.
        </p>
        <p>
          All answers are anonymous and exist in the Field. We never ask for
          your personal data.
        </p>
      </div>
    </div>
  );
};

export default Onboarding02;
