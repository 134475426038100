import React from "react";
import { useSelector } from "react-redux";
import { Info } from "../../../common/icons";
import {
  IAppState,
  IControlsProps,
  IGraphData,
  IGraphStats,
} from "../../../types";

const FieldControls = ({ setMode, setQuestion, fieldId }: IControlsProps) => {
  const { generations, answers, questions } =
    useSelector(
      (state: IAppState) =>
        state.graph[fieldId] &&
        state.graph[fieldId].reduce(
          (obj: IGraphStats, item: IGraphData) => {
            obj.generations =
              item.generation > obj.generations
                ? item.generation
                : obj.generations;
            obj.answers += item.size;
            obj.questions += 1;
            return obj;
          },
          {
            generations: 0,
            answers: 0,
            questions: 0,
          }
        )
    ) || {};

  return (
    <div className="field-controls">
      <div className="answer-ui">
        <button
          className="answer-button"
          onClick={() => {
            setMode("question");
            setQuestion();
          }}
        >
          Answer a question
        </button>
        <button className="info-button" onClick={() => setMode("info")}>
          <Info />
        </button>
      </div>
      <footer className="field-data">
        <div className="field-data-cell">
          <h5>Generations</h5>
          <span>{generations}</span>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle
              cx="5"
              cy="5"
              r="4.5"
              fill="white"
              stroke="black"
              strokeDasharray="1 2"
            />
          </svg>
        </div>
        <div className="field-data-cell">
          <h5>Questions</h5>
          <span>{questions}</span>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="black" />
          </svg>
        </div>
        <div className="field-data-cell">
          <h5>Answers</h5>
          <span>{answers}</span>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
            <circle cx="5" cy="5" r="4.5" fill="white" stroke="black" />
          </svg>
        </div>
      </footer>
    </div>
  );
};

export default FieldControls;
