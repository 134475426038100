import { Graphics } from "@pixi/graphics";

interface IDashedCircleProps {
  x?: number;
  y?: number;
  radius?: number;
  dash?: number;
  gap?: number;
  lineColour?: number;
  lineAlpha?: number;
  lineWeight?: number;
  fillColour?: number;
  fillAlpha?: number;
}

declare module "@pixi/graphics" {
  interface Graphics {
    dashedCircle(params: IDashedCircleProps): void;
  }
}

// degrees to radians
const d2r = (d: number) => (d * Math.PI) / 180;

Graphics.prototype.dashedCircle = function (params: IDashedCircleProps) {
  const defaultParams = {
    x: 0,
    y: 0,
    radius: 10,
    dash: 5,
    gap: 5,
    lineColour: 0x000000,
    lineAlpha: 1,
    lineWeight: 1,
  };
  const {
    x,
    y,
    radius,
    dash,
    gap,
    lineColour,
    lineAlpha,
    lineWeight,
    fillColour,
    fillAlpha,
  } = { ...defaultParams, ...params };

  if (radius === 0) return;

  const numDashes = (2 * Math.PI * radius) / dash;
  const degreesPerDash = 360 / numDashes;
  const numGaps = (2 * Math.PI * radius) / gap;
  const degreesPerGap = 360 / numGaps;
  let pos = 0;

  if (fillColour) {
    this.beginFill(fillColour, fillAlpha || 1);
    this.drawCircle(x, y, radius);
  }

  while (pos <= 360) {
    this.lineStyle(lineWeight, lineColour, lineAlpha);
    this.arc(x, y, radius, d2r(pos), d2r(pos + degreesPerDash));
    this.endFill();
    pos += degreesPerDash + degreesPerGap;
  }
};
