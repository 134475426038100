import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Fields } from "../../common/icons";

import { loadFieldContent } from "../../state/actions";
import {
  IAppState,
  IConversation,
  IField,
  IGraphData,
  INodeDetail,
} from "../../types";
import { useInterval, useTimeout } from "../../utils/useTime";
import { ConversationSlider } from "../field/components";
import { ForceGraph } from "../force-graph";
import { Layout } from "../layout";
import { createConversation } from "../../common/conversation";
import { graphReduce } from "../../state/selectors";

const SLIDE_INTERVAL = 10000;

const Visualisation = () => {
  const [canShow, setCanShow] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const [showTime, setShowTime] = useState<number | null>(null);
  const [screenMode, setScreenMode] = useState("");
  const [conversation, setConversation] = useState<IConversation>([]);
  const [activeConversationId, setActiveConversationId] = useState("");
  const [slideInterval, setSlideInterval] = useState<number | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const dispatch = useDispatch();
  const startTime = useRef(Date.now());
  const MIN_LOAD_TIME = 5000;
  const ANIM_TIME = 1000;

  const fieldId =
    useSelector((state: IAppState) => {
      const keys = Object.keys(state.graph);
      return keys.length ? parseInt(keys[0]) : null;
    }) || -1;

  const seeds = useSelector((state: IAppState) => {
    const keys = Object.keys(state.graph);
    const seeds: INodeDetail[] = [];

    if (keys.length) {
      const graph = state.graph[parseInt(keys[0])];
      for (const key in graph) {
        const { id, size, answer_id, generation }: IGraphData = graph[key];
        if (!answer_id) {
          const qid = `q${id}`;
          seeds.push({
            id: qid,
            size,
            type: "question",
            parent: "q0",
            generation,
            dbid: id,
          });
        }
      }
    }

    return seeds;
  });

  const { graph, parents } =
    useSelector((state: IAppState) => graphReduce(state, fieldId)) || {};

  const field: IField = useSelector(
    (state: IAppState) => state.fields[fieldId]
  );

  const hasLoaded = !!graph && !!fieldId;

  // Ask for a random field and graph
  useEffect(() => {
    dispatch(loadFieldContent(-1));
  }, [dispatch]);

  const showConversation = useCallback(() => {
    if (seeds.length) {
      const detail = seeds[Math.floor(Math.random() * seeds.length)];
      const { path, cid, convo } = createConversation(detail, graph, parents);

      if (path && cid && convo) {
        setActiveConversationId(cid);
        setConversation(convo);
        setScreenMode("conversation");
        setCurrentSlide(0);
        setSlideInterval(SLIDE_INTERVAL);

        const event = new CustomEvent("graphPath", {
          detail: {
            activePath: path,
          },
        });
        window.dispatchEvent(event);

        const gid = `${convo[0].type === "question" ? "q" : "a"}${convo[0].id}`;
        window.forceGraphFocus && window.forceGraphFocus(gid);
      }
    }
  }, [graph, parents, seeds]);

  useEffect(() => {
    window.addEventListener("graphTimelineComplete", showConversation);

    if (hasLoaded && showTime === null) {
      const now = Date.now();
      const diff = now - startTime.current;

      if (diff > MIN_LOAD_TIME) {
        setLoadComplete(true);
        setShowTime(ANIM_TIME);
      } else {
        const remaining = MIN_LOAD_TIME - diff;
        setRemainingTime(remaining);
        setShowTime(remaining + ANIM_TIME);
      }
    }

    return () => {
      window.removeEventListener("graphTimelineComplete", showConversation);
    };
  }, [graph, seeds, parents, hasLoaded, showTime, showConversation]);

  useInterval(() => {
    const newSlide = currentSlide + 1;
    if (conversation[newSlide]) {
      const chat = conversation[newSlide];
      const gid = `${chat.type === "question" ? "q" : "a"}${chat.id}`;
      setCurrentSlide(newSlide);
      setActiveConversationId(gid);
    } else {
      setCurrentSlide(0);
      setSlideInterval(null);
      showConversation();
    }
  }, slideInterval);

  useTimeout(() => {
    setLoadComplete(true);
  }, remainingTime);

  useTimeout(() => {
    setCanShow(true);
  }, showTime);

  const Header = useCallback(
    ({ field }: { field?: IField }) => (
      <header className="vis-header">
        <div className="logo">
          <Fields />
        </div>
        <div className="title">
          <h1>{field?.title}</h1>
          <h2>{field?.contributors}</h2>
        </div>
      </header>
    ),
    []
  );

  const FieldsLoader = (
    <div className="vis-loader">
      <Field />
      <Fields />
    </div>
  );
  return (
    <Layout
      condition={!!graph && !!fieldId && canShow}
      showHeader={false}
      customLoader={FieldsLoader}
      className={`vis ${loadComplete && !canShow ? "transition-out" : ""}`}
    >
      {/* {fieldId && field && ( */}
      <div
        className={`vis-wrapper ${
          screenMode === "conversation" ? "showing" : ""
        }`}
      >
        <Header field={field} />
        <ForceGraph fieldId={fieldId} presentationMode={true} />
        <ConversationSlider
          conversation={conversation}
          activeId={activeConversationId}
          setMode={setScreenMode}
          setQuestion={() => {}}
          presentationMode={true}
        />
      </div>
      {/* )} */}
    </Layout>
  );
};

export default Visualisation;
