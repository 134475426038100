import { localStorageGet } from "./local-storage";
import axios from "axios";

axios.interceptors.request.use((config) => {
  const token = localStorageGet("apiToken");
  if (token && config.url?.startsWith("/.netlify/functions/")) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (config.url?.startsWith(`${process.env.REACT_APP_REMOTE_API_URL}`)) {
    config.headers["x-functions-key"] = process.env.REACT_APP_REMOTE_API_KEY;
  }

  return config;
});

const d = {};
export default d;
