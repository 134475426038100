import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reject, Tick } from "../../common/icons";
import { IAppState, IModerationQuestionProps } from "../../types";
import { updateQuestion } from "../../state/actions";

const ModerationQuestion = ({ qid, fieldId }: IModerationQuestionProps) => {
  const { question, status, answer, path, answers } = useSelector(
    (state: IAppState) => state.questions[fieldId][qid] || null
  );
  const [questionStatus, setStatus] = useState(status);
  const dispatch = useDispatch();

  const approve = (id: number) => {
    setStatus(1);
    dispatch(updateQuestion(id, 1));
  };

  const reject = (id: number) => {
    setStatus(2);
    dispatch(updateQuestion(id, 2));
  };

  useEffect(() => {
    setStatus(status);
  }, [status]);

  return question ? (
    <div className={`question-row`}>
      <div className="question-row-content">
        <div className="a">
          <span className="answer-content">A: {answer || ""}</span>
          <span className="gen">GEN {path.split(".").length}</span>
          {answers > 0 && <span className="gen">{answers}</span>}
        </div>
        <div className="q">Q: {question}</div>
      </div>
      <div className="question-row-controls">
        <button
          className={questionStatus === 1 ? "active" : ""}
          onClick={() => approve(qid)}
        >
          <Tick />
        </button>
        <button
          className={questionStatus === 2 ? "active" : ""}
          onClick={() => reject(qid)}
          disabled={questionStatus === 1 && answers > 0}
        >
          <Reject />
        </button>
      </div>
    </div>
  ) : null;
};

export default ModerationQuestion;
