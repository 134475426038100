import {
  IGraphData,
  IConversation,
  IConversationComponent,
  INodeDetail,
  IGraphDataCollection,
  IParentCollection,
} from "../types";

export const createConversation = (
  eventDetail: INodeDetail,
  graph: IGraphDataCollection,
  parents: IParentCollection
) => {
  const { dbid, type, parent = "", answer, created_at } = eventDetail;
  const cid = `${type === "question" ? "q" : "a"}${dbid}`;
  let questionData = null;
  let isLeaf = false;

  if (graph && dbid) {
    if (type === "answer") {
      // Look for the question that was produced by this answer
      questionData =
        Object.values(graph).find((q) => q.answer_id === dbid) || null;

      // Might be one of the user's own answers
      // Look for the question it came from, rather than the question that it spawned
      if (!questionData) {
        const pqid = parseInt(parent.substring(1));
        questionData = Object.values(graph).find((q) => q.id === pqid) || null;
        isLeaf = !!questionData;
      }
    } else {
      questionData = graph[dbid] || null;
    }

    if (questionData) {
      let candidates: IGraphData[] = [];

      if (isLeaf) {
        candidates = [questionData];
      } else {
        for (let gd of Object.values(graph)) {
          if (gd.path.startsWith(questionData.path) && !parents[gd.id]) {
            candidates.push(gd);
          }
        }
      }

      if (candidates.length) {
        const randLeaf =
          candidates[Math.floor(Math.random() * candidates.length)];

        const questionList = randLeaf.path.split(".");
        const convo: IConversation = [];
        const path: string[] = ["q0"];

        for (const qid of questionList) {
          const qn = graph[parseInt(qid)];

          if (qn.answer_id) {
            const convoAnswer: IConversationComponent = {
              id: qn.answer_id,
              type: "answer",
              content: qn.answer,
              created_at: qn.answer_time,
            };
            path.push(`a${qn.answer_id}`);
            convo.push(convoAnswer);
          }

          const convoQuestion: IConversationComponent = {
            id: qn.id,
            type: "question",
            content: qn.question,
            created_at: qn.created_at,
            generation: qn.generation,
            path: qn.path,
          };
          convo.push(convoQuestion);
          path.push(`q${qn.id}`);
        }

        if (isLeaf) {
          const leafAnswer: IConversationComponent = {
            id: dbid,
            type: "answer",
            content: answer || "",
            created_at: created_at || "",
            mine: true,
          };

          convo.push(leafAnswer);
          path.push(`a${dbid}`);
        }
        return {
          cid,
          convo,
          path,
        };
      }
    }
  }

  return {};
};
