import React from "react";
import { Header } from "../header";
import { ContentLoader } from "../content-loader";
import { useSelector } from "react-redux";
import { IAppState } from "../../types";

export interface ILayoutProps {
  children?: any;
  className?: string;
  title?: string;
  showHeader?: boolean;
  customLoader?: JSX.Element;
  condition?: boolean;
}

const Layout = ({
  children = null,
  className = "",
  title = "",
  showHeader = true,
  customLoader,
  condition = true,
}: ILayoutProps) => {
  const contentStatus = useSelector((state: IAppState) => state.contentStatus);
  const compiledClassName = `${className} layout-wrap ${
    contentStatus && condition ? "loaded" : "loading"
  }`;

  const loader = customLoader || <ContentLoader />;

  return (
    <div className={compiledClassName}>
      {showHeader && <Header title={title} />}
      <main>{contentStatus && condition ? children : loader}</main>
    </div>
  );
};

export default Layout;
