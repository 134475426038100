import React, { useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { Login } from "./components/login";
import { Field } from "./components/field";
import { Home } from "./components/home";
import { ManageField } from "./components/manage-field";

import { Provider, useDispatch } from "react-redux";
import store from "./state/store";
import { useAuth0 } from "@auth0/auth0-react";
import { Fields, InactiveFields } from "./components/fields";
import { Label } from "./components/label";
import { Labels } from "./components/labels";
import { IPads } from "./components/ipads";
import { IPad } from "./components/ipad";
import { Manage } from "./components/manage";
import { NotFound } from "./components/not-found";
import { Moderation, FieldModeration } from "./components/moderation";
import { Visualisation } from "./components/fields-visualisation";

import {
  localStorageSet,
  localStorageRemove,
  localStorageGet,
} from "./utils/local-storage";
import { Slide, toast, ToastContainer } from "react-toastify";
import "./utils/api-intercept";

import "react-toastify/dist/ReactToastify.css";
import { initContent } from "./state/actions";

// Public URLs don't need to fetch all ipad and field data
const needsData = (pathname: string) => {
  // const labelsMatch = /\/labels\/[0-9]+\/*/g;
  // const fieldsMatch = /\/fields\/[0-9]+\/*/g;
  // const visualisationMatch = /\/visualisation\/*/g;

  // return !(
  //   pathname.match(labelsMatch) ||
  //   pathname.match(fieldsMatch) ||
  //   pathname.match(visualisationMatch)
  // );
  const manageMatch = /\/manage\/*/g;
  return pathname.match(manageMatch)
};

const App = () => {
  const dispatch = useDispatch();

  // Init store
  useEffect(() => {
    if (needsData(window.location.pathname)) {
      dispatch(initContent());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router style={{ height: "100%" }}>
      <RouterPage path="/" isPublic={true} component={<Home />} />
      <RouterPage
        path="/fields/:fieldId"
        isPublic={true}
        component={<Field />}
      />
      <RouterPage path="/labels" isPublic={true} component={<Labels />} />
      <RouterPage
        path="/labels/:ipadId"
        isPublic={true}
        component={<Label />}
      />
      <RouterPage
        path="/visualisation"
        isPublic={true}
        component={<Visualisation />}
      />
      <RouterPage path="/manage" component={<Manage />} isPublic={true} />
      <RouterPage path="/manage/fields" component={<Fields />} />
      <RouterPage
        path="/manage/fields/inactive"
        component={<InactiveFields />}
      />
      <RouterPage path="/manage/ipads" component={<IPads />} />
      <RouterPage path="/manage/ipads/:ipadId" component={<IPad />} />
      <RouterPage path="/manage/moderation" component={<Moderation />} />
      <RouterPage
        path="/manage/moderation/:fieldId"
        component={<FieldModeration />}
      />
      <RouterPage
        path="/manage/fields/:fieldId/edit"
        component={<ManageField />}
      />
      <RouterPage default component={<NotFound />} isPublic={true} />
    </Router>
  );
};

const RouterPage = ({
  component,
  isPublic = false,
}: { component: JSX.Element; isPublic?: boolean } & RouteComponentProps) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [apiToken, setAPIToken] = useState(localStorageGet("apiToken"));

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_JWT_AUDIENCE,
        });
        localStorageSet("apiToken", token);
        setAPIToken(token);
      })();
    } else {
      localStorageRemove("apiToken");
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return (isAuthenticated && apiToken) || isPublic ? component : <Login />;
};

const WrappedApp = () => {
  return (
    <Provider store={store}>
      <App />
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
        hideProgressBar={true}
        transition={Slide}
        autoClose={2000}
      />
    </Provider>
  );
};

export default WrappedApp;
