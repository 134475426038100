import React, { useRef, useEffect, useState } from "react";
import { Layout } from "../layout";
import { IAppState, IConversation } from "../../types";
import { useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import {
  localStorageGet,
  localStorageRemove,
  localStorageSet,
} from "../../utils/local-storage";
import {
  ConversationSlider,
  Description,
  FieldControls,
  Intro,
  Menu,
  QuestionModal,
} from "./components";
import { Onboarding } from "./onboarding";
import { FieldClose } from "../../common/icons";
import { ForceGraph } from "../force-graph";
import { loadFieldContent } from "../../state/actions";
import { LOCALSTORAGE_VERSION } from "../../common/settings";
import { graphReduce } from "../../state/selectors";
import { createConversation } from "../../common/conversation";

const Field = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const qid = parseInt(urlParams.get("qid") || "-1");
  const { fieldId } = useParams();
  const field = useSelector((state: IAppState) => state.fields[fieldId]);
  const { graph, parents } =
    useSelector((state: IAppState) => graphReduce(state, fieldId)) || {};

  const thisFieldVisited = useRef(localStorageGet(`field-${fieldId}`, false));
  const fieldVisited = useRef(
    localStorageGet(`fields-visited`, false) || thisFieldVisited.current
  );

  const [seenFields, setSeenFields] = useState(fieldVisited.current);
  const [onboardingStep, setOnboardingStep] = useState<number>(0);
  const [indexShowing, setIndexShowing] = useState(false);
  const [screenMode, setScreenMode] = useState(
    qid !== -1 ? "question" : "controls"
  );
  const [conversation, setConversation] = useState<IConversation>([]);
  const [activeQuestionId, setActiveQuestionId] = useState(qid);
  const [activeConversationId, setActiveConversationId] = useState("");

  const dispatch = useDispatch();

  // Remove the query if it's there to prevent unqanted reloading of question
  if (qid !== -1) {
    window.history &&
      window.history.replaceState(
        {},
        document.title,
        `${document.location.origin}${document.location.pathname}`
      );
  }

  useEffect(() => {
    if (field) {
      localStorageSet(`field-${field.id}`, true);
      localStorageSet(`fields-visited`, true);

      // Remove old answers if the version is outdated
      const answers = JSON.parse(localStorageGet("answers", "{}"));
      if (answers.version !== LOCALSTORAGE_VERSION) {
        localStorageRemove("answers");
      }
    }
  }, [field]);

  useEffect(() => {
    dispatch(loadFieldContent(fieldId));
  }, [dispatch, fieldId]);

  useEffect(() => {
    if (screenMode !== "conversation") {
      window.forceGraphFocus && window.forceGraphFocus(-1);
    }
  }, [screenMode]);

  const completeOnboarding = () => {
    localStorageSet(`fields-visited`, true);
    setSeenFields(true);

    if (screenMode === "info") {
      setScreenMode("controls");
      setOnboardingStep(0);
    }
  };

  const setRandomQuestion = () => {
    const keys = Object.keys(graph);
    let randoKey: number = parseInt(
      keys[Math.floor(Math.random() * keys.length)]
    );
    // keep searching til we find an active question
    while (graph[randoKey].status !== 1) {
      randoKey = parseInt(keys[Math.floor(Math.random() * keys.length)]);
    }

    setActiveQuestionId(graph[randoKey].id);
  };

  useEffect(() => {
    const handleGraphClick = (e: any) => {
      const { type } = e.detail;

      if (type === "root") {
        setScreenMode("description");
      } else {
        const { path, cid, convo } = createConversation(
          e.detail,
          graph,
          parents
        );

        if (path && cid && convo) {
          setActiveConversationId(cid);
          setConversation(convo);
          setScreenMode("conversation");

          const event = new CustomEvent("graphPath", {
            detail: {
              activePath: path,
            },
          });
          window.dispatchEvent(event);
        }
      }
    };
    window.addEventListener("graphClick", handleGraphClick);

    return () => {
      window.removeEventListener("graphClick", handleGraphClick);
    };
  }, [graph, parents, setScreenMode]);

  const FieldNav = () => (
    <nav className="field-nav">
      <button
        onClick={() => {
          if (screenMode === "description" || screenMode === "info") {
            setScreenMode("controls");
          } else {
            setIndexShowing(!indexShowing);
          }
        }}
      >
        {screenMode === "description" || screenMode === "info"
          ? "Close"
          : "Index"}
      </button>
      {!indexShowing ? (
        <h1>{field.title}</h1>
      ) : (
        <button
          className="index-close-button"
          onClick={() => setIndexShowing(false)}
        >
          <FieldClose /> Close
        </button>
      )}
    </nav>
  );

  return (
    <Layout
      showHeader={false}
      className="field"
      title={field?.title}
      customLoader={<Intro />}
      condition={!!field && !!graph}
    >
      {field &&
        (seenFields ? (
          <div className="field-panel field-main">
            <FieldNav />
            <div className="field-content">
              <ForceGraph fieldId={fieldId} />
              {indexShowing && (
                <Menu
                  currentId={fieldId}
                  handleClose={() => setIndexShowing(false)}
                />
              )}
              {screenMode === "controls" && (
                <FieldControls
                  setMode={setScreenMode}
                  setQuestion={setRandomQuestion}
                  fieldId={fieldId}
                />
              )}
              {screenMode === "conversation" && (
                <ConversationSlider
                  conversation={conversation}
                  activeId={activeConversationId}
                  setMode={setScreenMode}
                  setQuestion={setActiveQuestionId}
                />
              )}
              {screenMode === "question" && (
                <QuestionModal
                  qid={activeQuestionId}
                  toggleModal={setScreenMode}
                  setNewQuestion={setRandomQuestion}
                  fieldId={fieldId}
                />
              )}
              {screenMode === "description" && (
                <Description fieldId={fieldId} />
              )}
              {screenMode === "info" && (
                <Onboarding
                  onboardingStep={onboardingStep}
                  setOnboardingStep={setOnboardingStep}
                  completeOnboarding={completeOnboarding}
                />
              )}
            </div>
          </div>
        ) : (
          <Onboarding
            onboardingStep={onboardingStep}
            setOnboardingStep={setOnboardingStep}
            completeOnboarding={completeOnboarding}
          />
        ))}
    </Layout>
  );
};

export default Field;
