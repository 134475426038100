import { Viewport } from "pixi-viewport";
import * as PIXI from "pixi.js";

const QUESTION_SIZE = 20;
export const nodeSize = (type: string, extra: number = 0) => {
  const sizes: { [type: string]: number } = {
    root: 100,
    answer: 20,
  };

  return sizes[type] ? sizes[type] : QUESTION_SIZE + QUESTION_SIZE * 2 * extra;
};

export const centreGraph = (
  viewport: Viewport,
  position: PIXI.Point,
  overrides = {}
) => {
  const defaultConfig = {
    position,
    time: 500,
    ease: "easeOutQuad",
    scale: 0.5,
  };
  viewport.animate({ ...defaultConfig, ...overrides });
};
