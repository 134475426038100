import React from "react";
import ob01 from "./ob-01.svg";

const Onboarding01 = () => {
  return (
    <div className="onboarding-slide onboarding-slide-01">
      <div className="onboarding-image">
        <img src={ob01} alt="Onboarding, step 1" />
      </div>
      <div className="onboarding-copy">
        <p>Fields are experiments. </p>
        <p>
          Conversational spaces around the themes of each artwork. You can
          explore a Field or contribute to it.
        </p>
      </div>
    </div>
  );
};

export default Onboarding01;
