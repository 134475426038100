import React from "react";
import { Layout } from "../layout";
import { IAppState, IField, IFieldCollection } from "../../types";
import { useSelector } from "react-redux";
import { Link } from "@reach/router";
import { Fields } from "../../common/icons";

const Home = () => {
  const fields = useSelector((state: IAppState) =>
    Object.values(state.fields).reduce(
      (reduction: IFieldCollection, field: IField) => {
        if (field.active === 1) {
          reduction[field.id] = field;
        }
        return reduction;
      },
      {}
    )
  );
  const hasFields = Object.keys(fields).length > 0;
  const isProd = process.env.NODE_ENV === "production";

  return (
    <Layout showHeader={!isProd}>
      {hasFields ? (
        <div className="button-list-wrap">
          {!isProd ? (
            <div className="button-list">
              {Object.entries(fields).map(([_, value]) => {
                return (
                  <Link
                    className="button"
                    key={`field-${value.id}`}
                    to={`/fields/${value.id}`}
                  >
                    {value.title}
                  </Link>
                );
              })}
            </div>
          ) : (
            <Fields fill="#000000" />
          )}
        </div>
      ) : null}
    </Layout>
  );
};

export default Home;
