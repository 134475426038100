import React from "react";

const DottedRect = () => {
  return (
    <svg
      width="320"
      height="891"
      viewBox="0 0 320 891"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="dotted-rect"
    >
      <rect
        x="0.5"
        y="0.5"
        width="319"
        height="890"
        stroke="black"
        strokeDasharray="2 2"
      />
    </svg>
  );
};

export default DottedRect;
