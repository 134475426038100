import React from "react";
import { Link } from "@reach/router";
import { LoginButton, LogoutButton } from "../auth";
import { useAuth0 } from "@auth0/auth0-react";
import { Fields } from "../../common/icons";

export interface IHeaderProps {
  title: string;
}

const Header = (props: IHeaderProps) => {
  const { isAuthenticated } = useAuth0();
  const { title = "" } = props;

  return (
    <div>
      <header>
        <nav>
          {isAuthenticated ? (
            <>
              <Link to="/manage/fields">Fields</Link>
              <Link to="/manage/ipads">iPads</Link>
              <Link to="/manage/moderation">Moderation</Link>
              <LogoutButton />
            </>
          ) : (
            <LoginButton />
          )}
        </nav>
        <Link className="site-title" to="/">
          <Fields fill="#FFFFFF" />
        </Link>
      </header>
      {title !== "" ? <div className="sub-head">{title}</div> : null}
    </div>
  );
};

export default Header;
