import React from "react";
import { useSelector } from "react-redux";
import { IAppState, IDescriptionProps } from "../../../types";

const Description = ({ fieldId }: IDescriptionProps) => {
  const field = useSelector((state: IAppState) => state.fields[fieldId]);

  return (
    <div className="field-overlay-content field-description">
      <div className="field-overlay-content-inner">
        <h2>{field.contributors}</h2>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: field.description,
          }}
        />
      </div>
    </div>
  );
};

export default Description;
