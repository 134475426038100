import React, { FormEventHandler, MouseEvent } from "react";
import { FormElem, IQAProps } from "../../types";

type qaKEy = "question" | "answer";

const QA = ({ index, data, updateQA, removeQA }: IQAProps) => {
  const handleChange: FormEventHandler<FormElem> = (e) => {
    const {
      currentTarget: { name, value },
    } = e;

    const newData = { ...data };
    newData[name as qaKEy] = value;
    updateQA(index, newData);
  };

  const handleRemove = (e: MouseEvent) => {
    e.preventDefault();
    removeQA(index);
  };

  return (
    <div className="qa">
      <div className="qa-input">
        <span>Q: </span>
        <input
          type="text"
          name={`question`}
          value={data?.question}
          onChange={handleChange}
        />
      </div>
      <button className="removeButton" onClick={handleRemove}>
        -
      </button>
    </div>
  );
};

export default QA;
