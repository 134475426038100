import React from "react";

const Crescent = () => {
  return (
    <svg
      width="320"
      height="160"
      viewBox="0 0 320 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="crescent"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160 0H0V160C0 71.6344 71.6344 0 160 0ZM320 160V0H160C248.366 0 320 71.6344 320 160Z"
        fill="#F6F7F2"
      />
    </svg>
  );
};

export default Crescent;
