import React, { FormEventHandler, useState, MouseEvent } from "react";
import { Layout } from "../layout";
import { FormElem, IAppState, IIPad } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { Link, navigate } from "@reach/router";
import { Edit } from "../../common/icons";
import axios from "axios";
import { setIPads } from "../../state/actions";
import notify from "../../utils/notify";

const defaultFieldData = { name: "" };

const IPads = () => {
  const iPads: IIPad[] = useSelector((state: IAppState) => state.ipads);
  const hasData = Object.keys(iPads).length > 0;
  const [overlay, setOverlay] = useState(false);
  const [overlaySaving, setOverlaySaving] = useState(false);
  const [ipadData, setIPadData] = useState({ ...defaultFieldData });
  const dispatch = useDispatch();

  const createIPad = (e: MouseEvent) => {
    e.preventDefault();
    setOverlaySaving(true);

    try {
      axios
        .post(`/.netlify/functions/addIPad`, { ipad: { ...ipadData } })
        .then(({ data }) => {
          dispatch(setIPads([data]));
          setOverlaySaving(false);
          navigate(`/manage/ipads/${data.id}`);
        });
    } catch (e) {
      notify("Error creating IPad label", "error");
    }
  };

  const newIPad = (e: MouseEvent) => {
    e.preventDefault();
    setIPadData({ ...defaultFieldData });
    setOverlay(true);
  };

  const closeOverlay = (e: MouseEvent) => {
    e.preventDefault();
    setOverlay(false);
  };

  const handleChange: FormEventHandler<FormElem> = (e) => {
    const {
      currentTarget: { name, value },
    } = e;

    const newFieldData = { ...ipadData };
    newFieldData[name as "name"] = value;
    setIPadData(newFieldData);
  };

  const overlayForm = (
    <div className={`overlay-container ${overlaySaving ? "saving" : ""}`}>
      <form>
        <h3>Create new iPad Label</h3>
        <div className="form-row">
          <label>Name</label>
          <input
            value={ipadData.name}
            type="text"
            name="name"
            onChange={handleChange}
          />
        </div>
        <button onClick={createIPad}>Create</button>
        <button className="cancel" onClick={closeOverlay}>
          Cancel
        </button>
      </form>
    </div>
  );

  return (
    <Layout>
      {hasData ? (
        <div className="ipads content">
          <div className="title-bar">
            <h1>iPads</h1>
            <button onClick={newIPad}>Create new</button>
          </div>
          <div className="edit-items">
            {Object.entries(iPads).map(([_, value]) => {
              return (
                <Link
                  className="button"
                  key={`ipad-${value.id}`}
                  to={`/manage/ipads/${value.id}`}
                >
                  {value.name}
                  <Edit />
                </Link>
              );
            })}
          </div>
          {overlay && overlayForm}
        </div>
      ) : null}
    </Layout>
  );
};

export default IPads;
